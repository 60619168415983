import {
        Container,
        BarContainer,
        BarItem,
        BarItemDiv,
        ContainerExperimentType,
        ContainerTypeBody,
        ContentBody,
        ContentTitle,
        ContentText
} from "./styles"
import { useNavigate, useLocation } from 'react-router-dom';
import { useModal } from '../Pattern/Modal';
import experimentoListImg from '../../assets/images/list.svg'
import addExperiment from '../../assets/images/add.svg'
import settingsImg from '../../assets/images/settings.svg'
import logoutImg from '../../assets/images/logout.svg'
import editImg from '../../assets/images/edit.svg'

import Cookies from "js-cookie";
import { useState } from "react";

export function Bar(){
    const navigate = useNavigate();
    const location = useLocation();
    const { addModal, closeModal } = useModal();

    function logOut(){
        if(Cookies.get('sensorie-hash-experiment')){
                addModal({
                        body:<p style={{ color:"#575757" }}>
                                Deseja finalizar a edição e seguir com o logout?
                        </p>,
                        buttonPrimary:{ label:"Sim", function: ()=>{
                                Cookies.remove('sensorie-hash-experiment');
                                Cookies.remove('sensorie-name-experiment');
                                Cookies.remove('sensorie-type-experiment');
                                navigate('/');
                        }},
                        buttonSecondary:{ label: "Não", color:"#525252" }
                })
        }else navigate('/')
    }

    function handleNavigateToNewExperiment(type){
        navigate(`/newexperiment/new/${type}`);
        closeModal()     
    }

    function modal(){
        addModal({ 
                body: 
                <ContainerExperimentType>
                  <h3>Qual tipo de teste você deseja aplicar?</h3>
                        <ContainerTypeBody>
                                <ContentBody>
                                        <ContentTitle>
                                                <h4 style={{whiteSpace:'nowrap'}}> Discriminativos <br/> (2 amostras)</h4>
                                        </ContentTitle>
                                        <ContentText onClick={()=>{handleNavigateToNewExperiment('Triangle')}}>Triangular</ContentText>
                                        
                                </ContentBody>
                                <ContentBody style={{display:'none'}}>
                                        <ContentTitle>
                                                <h4>Discriminativos <br/> (3+ amostras)</h4>
                                        </ContentTitle>
                                        <ContentText onClick={()=>{handleNavigateToNewExperiment('Control Difference')}}>Diferença do controle / DOD</ContentText>
                                        
                                </ContentBody>
                                <ContentBody>
                                        <ContentTitle>
                                                <h4>Descritivo</h4>
                                        </ContentTitle>
                                        <ContentText onClick={()=>{handleNavigateToNewExperiment('Descriptive Analysis')}}>Análise Descritiva</ContentText>
                                        
                                </ContentBody>
                                <ContentBody>
                                        <ContentTitle>
                                                <h4>Afetivos</h4>
                                        </ContentTitle>
                                        <ContentText onClick={()=>{handleNavigateToNewExperiment('Acceptance')}}>Aceitação</ContentText>
                                </ContentBody>

                        </ContainerTypeBody>
                </ContainerExperimentType>,
                buttonSecondary: 'Fechar',
                type:true
                })
                
    }

    function handleEditOrNewExperiment(){

        let expHash = Cookies.get("sensorie-hash-experiment");

        if(expHash){
          let expType = Cookies.get("sensorie-type-experiment");
          navigate(`/newexperiment/${expHash}/${expType}`);

        }else modal()

    }

    function handleGoToList(){

        if(Cookies.get('sensorie-hash-experiment')){
                addModal({
                        body:<p style={{ color:"#575757" }}>
                                Deseja finalizar a edição e retornar à lista de experimentos?
                        </p>,
                        buttonPrimary:{ label:"Sim", function: ()=>{
                                navigate('/experiments');
                                Cookies.remove('sensorie-hash-experiment');
                                Cookies.remove('sensorie-name-experiment');
                                Cookies.remove('sensorie-type-experiment');
                        }},
                        buttonSecondary:{ label: "Não", color:"#525252" }
                })
        }else navigate('/experiments')

    }

    return(
        <Container blurBottom = {location.pathname==='/experiments'?true:false}>
            <BarContainer>
            <BarItem onClick={handleGoToList}>
                    <img src={experimentoListImg} alt="Experimentos" />
            </BarItem>
            <BarItem onClick={handleEditOrNewExperiment}>
                   {Cookies.get("sensorie-hash-experiment") ?
                   <div style={{ color:'white', fontWeight:'bold', borderRadius:"20px", backgroundColor:'rgb(0,0,0,0.2)', width:'fit-content', maxWidth:'100%', padding:'5px 15px' }}>
                        {Cookies.get("sensorie-name-experiment") !== undefined ? Cookies.get("sensorie-name-experiment") : "Novo Experimento"}<img style={{ marginLeft:10 }} height={17} src={editImg} alt="Editar"/>
                   </div>
                   :
                        <img src={addExperiment} alt="Novo experimento" />
                   }
            </BarItem>
            {/* <BarItem to='/settings'>
                    <img src={settingsImg} alt="Configurações" />
            </BarItem> */}
            <BarItemDiv onClick={logOut}>
                    <img src={logoutImg} alt="Sair" />
            </BarItemDiv>

            </BarContainer>
        </ Container>
    )
}