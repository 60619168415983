import styled, { keyframes } from 'styled-components'


export const ListItem = styled.div`

display:flex;
flex-direction:row;
align-items:center;
justify-content:space-around;
width:98%;
min-height:2.5rem;
border-radius:7rem;
font-size:12px;
padding:7px 7px 7px 1rem;
margin-bottom:8px;
background-color:#F2F2F2;
color:#323232;

img{
  cursor: pointer;
  max-width: 1rem;
  max-height: 1rem;
  margin-left: .5rem;

  &:hover{
      filter: brightness(1.5);
  }
}

`;

export const ScroolView = styled.div`
  display:flex;
  z-index:0;
  overflow-y:auto; 
  max-height: 300px;
  flex-direction:column; 
  width:100%; 
  scroll-behavior:smooth;
  
  ::-webkit-scrollbar {
    width: 8px;
    
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

`;

export const ScrollableDiv = styled.div`
  max-height: 300px;
  width: 98%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 var(--purple-second);
  margin: 10px;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb{
    background:var(--purple-second);
    border-radius:3px;
    }
`;
