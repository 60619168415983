import styled, { keyframes} from 'styled-components'

export const Container = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

`;

export const Message = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 25vh;
    margin-top: 5rem;
    border-radius: 1rem;
    border: 1px solid var(--purple-main);
    width: 50%;

    h5{
      color: var(--purple-main);
    }
`;

export const Page = styled.div`

    display: flex;
    flex-direction: column;
    width: 70%;
    border: 1px solid grey;
    margin-top: 1rem;
    border-radius: 1rem;
    min-height: 90vh;
    padding: 1rem;
    margin-bottom: 3rem;

    button{
      height: 2rem;
      font-size: 1.2rem;
      color: #FFF;
      background: var(--purple-main);
      border-radius: .5rem;
      border: 2px solid var(--purple-main);
      transition: all .5s;

      &:hover{
        background: transparent;
        color: var(--purple-main);
        transition: all .5s;
      }
    }

`;


const pulse = keyframes`
from {
  transform: scale(1);
}

to {
  transform: scale(1.1);
}
`;

export const Logo = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 1rem;
  left: 1.25rem;
  height: 4rem;
  border-radius: 2.5rem;
  padding:12px;


  img{
      width: 3.5rem;

      animation: ${pulse} 2s infinite alternate;

  }

  h2{
    padding-left:2rem ;
    color: var(--purple-main);
  }


`;

export const ClientLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 1rem;
    right: 1.25rem;
    height: 4rem;
    border-radius: 2.5rem;
    padding:12px;


    img{
        width: 3.5rem;

        /* animation: ${pulse} 2s infinite alternate; */

    }

    h2{
        padding-left:2rem ;
        color: var(--purple-main);
    }
`