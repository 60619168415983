import { useState } from 'react';
import { Container, LoginContainer, Logo, Form, Input, Footer } from './styles';
import logoImg from '../../assets/images/logo.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { pulsar } from 'ldrs'

import API from '../../services/api.jsx';
import Cookie from 'js-cookie';

const Login = () => {

  const [ data, setData ] = useState({ username:'', password:'' });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function letsLogIn(){

    if(data.username && data.password){
      API.post('/login', data)
      .then((res)=>{
        if(res.status === 200){
          setLoading(true);
          Cookie.set('sensorie-user-id', res.data.user.id)
          Cookie.set('sensorie-user-name', res.data.user.name)
          Cookie.set('token_sensorie',res.data.token.token, { expires: 0.5 })
          navigate('/experiments')
        }
      })
      .catch((err)=> {
        setLoading(false)
        window.alert("Credenciais incorretas!")
      })
      .finally(() => setLoading(false))
  }

  }
  pulsar.register()

  function changeValue(field, value){
    let aux = {...data};
    aux[field] = value;

    setData(aux);
  }

    return(
      <Container>
      <LoginContainer>

      <Logo>
        <img style={{ marginRight:5 }} width='60px' height='60px' src={logoImg} alt="Sensorie"/>
        <strong style={{ color:'#56179A' }}>Sensorie</strong>
      </Logo>

        <Form onSubmit={(e)=>{
          e.preventDefault()
          letsLogIn()
        }}>

            <Input type='text' id='username' placeholder=' usuário...' onChange={(e)=> changeValue('username', e.target.value)} autoFocus/>
            <Input type='password' id='password' placeholder=' senha...' onChange={(e)=> changeValue('password', e.target.value)}/>


            <Footer>

              <div style={{ fontSize:'12px', color:'grey' }}>V05</div>
              <div type='submit' style={{ backgroundColor:'#56179A', padding:'3px 9px', borderRadius:'20px', cursor:'pointer', fontSize:18 }} onClick={()=> letsLogIn()}>
              {loading? (
                <div style={{marginTop:2}}>
                  <l-pulsar
                    size="25"
                    speed="2" 
                    color="purple" 
                  ></l-pulsar>
                </div>
                
              ) : (
                <strong  style={{ color:'white' }}>Entrar</strong>
              )}
              </div>
            </Footer>
            <input type="submit" style={{ display: 'none' }} />


        </Form>

      </LoginContainer>
      
      </Container>
    )

 }

 export default Login;
