
import { Container, 
         ItemBoxOptions, 
         MainQuestionPart, 
         VariantQuestionPart ,
         RemoveItemLink,
         ArrTop,
         ArrDown
        } from './styles'

import { useState } from 'react'
import Switch from 'react-input-switch'

import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";



import duplicateImg from "../../../assets/images/duplicate-thumb.svg"
import deleteImg from "../../../assets/images/delete-thumb.svg"
import moreVertImg from "../../../assets/images/more-vert.svg"
import { useEffect } from 'react';
import API from '../../../services/api';


export const ItemForm = ({data, preview, formId, sample})=>{

const [onHover, setOnHover] = useState(false)
const [mandatory, setMandatory] = useState(data.mandatory?"Obrigatório":"Não obrigatório");
const [questionType, setQuestionType] = useState(data.type)
const [questionDescription, setQuestionDescription ] = useState(data.description)
const [linearRange, setLinearRange] = useState({min:0, max:100, step:1, value:0})
const [youtubeId, setYoutubeId] = useState('')

const [imgUrl, setImgUrl] = useState('')

const [ questionOptions, setQuestionOptions ] = useState(data.options?data.options.split(","):[''])


async function prepareImageLink(){

    const   fileUrl   = await API.post('/generatedownloadlink',{
        fileName: data.description,
      })

      setImgUrl(fileUrl?.data)
  }

async function saveAnswer(e){
    if(preview){
        return
    }

    if(formId>0){
        
        const payload = {
            form_id:formId,
            sample:sample,
            question_id: data.id,
            value:e.target.value
        }

        const result = await API.post('answers', payload)

        console.log(result.data)

    }

    

}


 function verifyLink(){

    if(questionDescription !== null && questionDescription !== undefined){
        
        const split4 = questionDescription.replaceAll('watch?v=', '$').replaceAll('&ab_channel', '$').split('$');

        setYoutubeId(split4[1])    
    }
    

 }

 async function prepareLinearRange(){

    const arr = data.options.split("#")

    console.log(arr)
    
    setLinearRange({min:arr[0], max:arr[1], step:arr[2], value:arr[0]})
 }

 useEffect(()=>{
    verifyLink()
    if(data.type==='linear'){
        prepareLinearRange()
    }

    if(data.type==='image'){
        prepareImageLink()
    }
    
 },[])



 



return(
    <Container onMouseEnter={()=>setOnHover(true)} onMouseLeave={()=>setOnHover(false)}>

        
        
        <MainQuestionPart>
        
            <div className={'w100PerCent'}>
                {
                    (questionType==='video' ||questionType==='paragraph' ||questionType==='image')
                    ?null
                    :<h5>{questionDescription}</h5>
                    // <input type="text" disabled value={questionDescription}/>
                }
                
            </div>
            
        </MainQuestionPart>
        

        <VariantQuestionPart>

            {questionType==='text'&&
            <>
                <input type="text" width={100} onBlur={saveAnswer} />
            </>}

            {questionType==='longtext'&&
            <>
                <textarea name="" id="" cols="30" rows="5" onBlur={saveAnswer} ></textarea>
            </>}

            {questionType==='multiple'&&
            <>
                {questionOptions.map((question,index)=><OptionQuestion 
                                                        id={data.id}
                                                        index={index} 
                                                        question={question} 
                                                        questionOptions = {questionOptions}
                                                        setQuestionOptions = {setQuestionOptions}
                                                        saveAnswer = {saveAnswer}
                                                        />)}
                
            </>
            }

            {questionType==='select'&&
            <>
                
                {questionOptions.map((question, index)=><CheckboxQuestion index={index} 
                                                        id={data.id}
                                                        question={question} 
                                                        questionOptions = {questionOptions}
                                                        setQuestionOptions = {setQuestionOptions}
                                                        saveAnswer = {saveAnswer}
                                                        />)}
                
            </>
            }

            {questionType==='linear'&&
            <>
            {/* <span>
                <p>Min</p>
                <input type="text" value={linearRange.min} onChange={(e)=>setLinearRange({...linearRange, min:e.target.value})}/>
                <p>Máx</p>
                <input type="text" value={linearRange.max} onChange={(e)=>setLinearRange({...linearRange, max:e.target.value})}/>
                <p>Passo</p>
                <input type="text" value={linearRange.step} onChange={(e)=>setLinearRange({...linearRange, step:e.target.value})}/>
            </span>
            
                <p>O participante verá um campo assim para responder:</p>*/}
                <div> 
                <input type="range" value={linearRange.value} min={linearRange.min} max={linearRange.max} step={linearRange.step} onChange={(e)=>setLinearRange({...linearRange, value:e.target.value})}/>
                <p>{linearRange.value}</p>
                </div>
            </>}

            {questionType==='date'&&
            <>
                <input type="date" width={100} onBlur={saveAnswer}  />
            </>}

            {questionType==='video'&&
            <>
                {/* <p>Digite o link do Youtube:</p>
                <input type="text" width={100}  /> */}
                {youtubeId
                ?<YoutubeEmbed embedId={youtubeId} />
                :null}
                
            </>}

            {questionType==='paragraph'&&
            
                <div>
                    <p className={questionOptions+'Class'}>
                        {questionDescription}
                    </p>


                </div>
                
            }

            {
                questionType==='image'&&
                <img 
                    className='imgItem'
                    src={imgUrl}
                />
                
            }
        
        </VariantQuestionPart>


    </Container>
)
}

export const OptionQuestion = ({ id, question, index, setQuestionOptions, questionOptions, saveAnswer })=>{


    return(

        <div >
            <input name={id} className='smallRadio' type="radio"  onBlur={saveAnswer} />
            <p style={{fontSize:'1rem'}}>{question} </p>
          
           
        </div>

    )
}

export const CheckboxQuestion = ({ id, question, index, setQuestionOptions, questionOptions, saveAnswer })=>{

  

    return(

        <div>
            <input className='smallRadio' type="checkbox"  onBlur={saveAnswer} />
            <p style={{fontSize:'1rem'}}>{question} </p>
            
        </div>

    )
}


export const YoutubeEmbed = ({embedId}) => {

    return(
        <div className="video-responsive">
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
    )
    };