import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    height: 7rem;
    width: 100%;
    position: fixed;
    bottom: 0;
    background:${(props)=>props.blurBottom?'rgb(255,255,255)':'transparent'};
    background:${(props)=>props.blurBottom?'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 100%)':'transparent'};

`;

export const BarContainer = styled.div`

    @media (min-width: 720px){
            border-radius: 30px;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
            width: 54%;

            bottom: 1.3rem;
            border-radius: 2rem;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

        }

    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: var(--purple-main);
    width: 100%;
    height: 3rem;

    position: fixed;
    bottom: 0;

    img{
      max-width: 1.5rem;
      max-height: 1.4rem;
      cursor: pointer;
      transition: transform 0.2s;

      &:hover{
          transform: scale(1.1);
      }
    }

`;

export const BarItem = styled(Link)`

    text-align: center;
    display: flex;
    img{
        max-width: 1.4rem;
        max-height: 1.3rem;
        cursor: pointer;
        transition: transform 0.1s;

        &:hover{
            transform: scale(1.1);
        }
    }

`;

export const BarItemDiv = styled.div`

    text-align: center;
    display: flex;
    img{
        max-width: 1.4rem;
        max-height: 1.3rem;
        cursor: pointer;
        transition: transform 0.1s;

        &:hover{
            transform: scale(1.1);
        }
    }

`;

export const ContainerExperimentType = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%
`

export const ContainerTypeBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 2%;
    width: 100%;
`

export const ContentBody = styled.div`
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction:column;
    cursor: pointer;
`

export const  ContentTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
    height: 25px;
`

export const ContentText = styled.div`
    background-color: var(--purple-main);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    width: 140px;
    height: 45px;
    justify-content: center;
    align-content: center;
    text-align: center;
`


