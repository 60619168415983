import { Container } from './styles'

import API from '../../services/api'
import ExperimentListItem from './ExperimentListItem'
import { useEffect, useState } from 'react'
import ExperimentListFilter from './ExperimentListFilter'

import Cookie from 'js-cookie';

const ExperimentList = ()=>{

    const [ experiments, setExperiments ] = useState([]);
    const user_id = Cookie.get('sensorie-user-id')

    useEffect(()=>{
        getExperiments()
    },[])

    async function getExperiments(){

        await API.get(`/experimentsByUser/${user_id}`).then((res)=>{
            setExperiments(res.data.experiment.sort((a,b)=>b.id - a.id))
        })
    }


    return(
        <Container>

            <ExperimentListFilter />

            {experiments?
              experiments.map((experiment, index)=>
                <ExperimentListItem key={experiment.id} index={index} data={experiment} reload={getExperiments} user={user_id}/>
              )
              :
              <></>
            }

        </Container>
    )
}

export default ExperimentList;
