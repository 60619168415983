import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    width: 80%;
    // border: 1px solid var(--purple-second);
    box-shadow: 1px 3px 10px #d9d9d9;
    height: 3.4rem;
    border-radius: 1.7rem;
    margin-bottom: 20px;

    @media (max-width: 719px){
            width: 95%;
    }

    div{
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        width: 40%;

        
        

        & + div{
            width: 20%;

        }

        

        p{
            color: var(--purple-main);
            font-weight: 600;

            &+p{
                font-weight: 400;
                color: var(--text-body);
                font-size: 0.8rem;
                padding-top: 0.2rem;
            }
        }

        img{
            cursor: pointer;
            max-width: 1rem;
            max-height: 1rem;
            margin-left: .5rem;

            &:hover{
                filter: brightness(1.5);
            }
        }

        &:last-child{
            @media (max-width: 719px){
            display: none;
    }
        }
    }

`;