import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 80%;
    padding-bottom: 2rem;
    padding-top: 1rem;
    
    @media (max-width: 719px){
            width: 95%;
    }

    

`;

export const BasicFilter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    div{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 50%;
        height: 2rem;
        border-radius: 1rem;

        img{
            max-width: 1rem;
            max-height: 1rem;
            position: absolute;
            margin-left: 1rem;
        }

        input{
            border: 0;
            padding-left: 1rem;
            height: 1.8rem;
            border: 1px solid #E5E4E2;
            height: 2rem;
            border-radius: 1rem;
            width: 100%;
            padding-left: 3rem;
        }

        & + div{
            width: 25%;
            justify-content: center;
            h5{
                color: var(--purple-main);
            }

            select{
                margin-left: 1rem;
                border: 0;
                background: transparent;
                color: #ADADAD;
            }
        }

        &:last-child{
            width: 25%;
            justify-content: flex-end;
            h5{
                cursor: pointer;
                color: #ADADAD;
                font-weight: 400;
            }
        }
    }
`;

export const AdvancedFilter = styled.div`
    display: ${(props)=>props.isActive?'flex':'none'};
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 1rem;

    h5{
        color: var(--purple-main);
    }

    input{
            border: 0;
            padding-left: 1rem;
            padding-right: 1rem;
            height: 1.8rem;
            border: 1px solid #E5E4E2;
            color: #ADADAD;
            height: 2rem;
            border-radius: 1rem;
            width: 20%;
            margin-left: 1rem;
        }

`;