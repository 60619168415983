import styled from 'styled-components'

export const Container = styled.span`

    .imgItem{
        width: unset;
        height: unset;
        margin: unset;
        padding: unset;
        max-width: unset;
        max-height: unset;
        position: unset;

        width: 95%;
        }


    .titleOption{
        display: flex;
        color: #d9d9d9;

        svg{
            cursor: pointer;
        }

        p{
            font-size: 1rem;
            margin-left: 1rem;
        }
    }


    display: flex;

    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    border: solid 1.2px #d9d9d9;
    width: 100%;
    border-radius: .3rem;
    padding: .5rem;
    position: relative;

    margin-bottom: 1rem;



`;

export const ParagraphInput = styled.textarea`

    font-size: ${(props)=>props.headText?'2rem':'1rem'};
    font-weight: ${(props)=>props.headText?'strong':'regular'};
    height: 10rem;
    width: 95% !important;
    margin: 0 auto !important;
    border: 1px solid #d9d9d9;
    border-radius: 1rem;
    padding: 1rem;

`

export const ArrTop = styled.span`

    display: ${(props)=>props.isOnHover?'block':'none'};

    color: var(--text-body);

    position:absolute;
    top: 2px;
    left: 1px;
    padding-left: -1rem;
    cursor: pointer;
    font-size: 12px;

`;

export const ArrDown = styled.span`

    display: ${(props)=>props.isOnHover?'block':'none'};

    color: var(--text-body);

    position:absolute;
    bottom: 2px;
    left: 1px;
    padding-left: -1rem;
    cursor: pointer;
    font-size: 12px;

`;

export const MainQuestionPart = styled.div`

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100% !important;
    position: relative;
    margin-bottom: 1rem;
    padding: .5rem;

    .w30PerCent{
            width: 30% !important;
        }

    .w60PerCent{
        width: 60% !important;
    }

    .w100PerCent{
        width: 100% !important;
    }



    div{
        display: flex;

        align-items: flex-start;
        justify-content: flex-start;

    }


    input, select{
        border: 0;
        border-bottom: solid 1px var(--purple-main);
        background: #FFF;
        height: 1.8rem;
        padding-left: .5rem;
        width: 100%;
        /* color: var(--text-body); */
        color: var(--purple-main);

        outline: none;

        &:focus{
            border-bottom-width: 2px;
        };

    }

    input{
        font-weight: bold;
    }


`;

export const VariantQuestionPart = styled.div`

    display: block !important;
    width: 100% !important;
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 10px;
    color: var(--text-body);
    input{
        margin-top: .2rem;
        width: 100%;
        outline: none;
        &:focus{
            border-bottom-width: 2px;
        };
    }

    textarea{
        margin-top: .2rem;
        width: 100%;
    }

    span{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1rem;

        p{
            width: 30%;
            margin-left: .2rem;
            white-space: nowrap;
        }

        input{
            display:flex;
            width: 100%;
            margin-left: .1rem;
            border: 0;
            border-bottom: solid 1px #d9d9d9;
            background: #FFF;
            color: var(--text-body);
        }

    }
    div{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        .smallRadio{
            width: 10% !important;
        }

        .optionWidth{
            width: 70% !important;
        }

        input{
            border: 0;
            border-bottom: solid 1px #d9d9d9;
            background: #FFF;
            color: var(--text-body);
            margin-top: 1rem;

        }
        p{
            margin-left: .5rem;
        }


    }

    .video-responsive {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 100%;
    }

    .video-responsive iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }

`;

export const RemoveItemLink = styled.p`
    padding-left: .5rem;

    display: ${(props)=>props.isVisible?'block':'none'};

`;

export const ItemBoxOptions = styled.span`
    display: ${(props)=>props.isOnHover?'block':'none'};
    position: absolute;
    right: 1rem;
    bottom: -0.6rem;
    padding-left: 1rem;

    align-items: center;
    justify-content: space-around;

    /* text-align: center; */

    width: 10rem;
    height: 1.4rem;
    background:#E5E4E2;
    border-radius: .6rem;



    div{
        display: inline-flex;
        align-items: center;
        justify-content: space-around;
        width: 60%;
        height: 100%;

        font-size: 8px;
        vertical-align: middle;
        color: var(--text-body);

        :last-child{
            width: 40%;
        }

        img{
            position: unset !important;
            margin-left: .1rem;
            cursor: pointer;
        }
    }

`;

 export const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #56179A;
  color: #333;
  resize: none;
  overflow: hidden;
  border-color: #56179A;
  height: auto;
  
  /* Estilize as barras de rolagem */
  scrollbar-width: thin;
  scrollbar-color: #ccc #f5f5f5;

  /* Estilize as barras de rolagem para navegadores baseados em WebKit */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
`;

