import React, { useState } from 'react';
import { GlobalStyle } from "./styles/global";
import { Bar } from "./components/Bar"
import ExperimentList from './components/ExperimentList';
// import { NewExperimentModal } from './components/NewExperimentModal';
import Screens from './routes';
import { ModalProvider } from './components/Pattern/Modal';

function App() {

  const [isNewExperimentModalOpen, setIsNewExperimentModalOpen] = useState(false);

    function handleOpenNewExperimentModal(){
      setIsNewExperimentModalOpen(true)
    }

    function handleCloseNewExperimentModal(){
      setIsNewExperimentModalOpen(false)
    }


  return (
    <div className="App">
    <ModalProvider>
      <Screens />
      </ModalProvider>
     <GlobalStyle />
    </div>
  );
}

export default App;
