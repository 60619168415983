import styled from "styled-components";

export const Container = styled.div`

    display: flex;
    flex-direction: column;
    min-height:130px;
    aspect-ratio:1/1.2;
    justify-content:center;
    background: #FFF;
    cursor: pointer;
    padding:7px;
    margin:10px;
    border-radius: 2px;
    font-size:14px;

    position: relative;
    border: solid 2px ${(props)=>props.selectedPage?'var(--purple-main)':'transparent'};
    text-overflow:ellipsis;

    &:hover{
      .hideOutHover{
        visibility:visible;
      }
    }

    &:not(:hover){
      .hideOutHover{
        visibility:hidden;
      }
    }


`;

export const PageIcons = styled.div`
position: absolute;
right: 7px;
bottom: 7px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    img{
        margin-top: .2rem;
    }
`;

export const PageDetail = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    height: 100%;

`;
