import React from 'react';
import { Container, PageIcons, PageDetail } from "./styles";
import duplicateImg from "../../../assets/images/duplicate-thumb.svg"
import deleteImg from "../../../assets/images/delete-thumb.svg"

const PageThumb = React.memo(({ remove, data, selectedPage, setSelectedPage, pageNumber, disabled }) => {
    return (
        <div>
             <Container selectedPage={selectedPage}>
            <div 
            style={{ display:'flex', width:'100%', justifyContent:'center', color:"#56179A", fontWeight:'bold' }}
            >{data.name}
            </div>
            <PageIcons className="hideOutHover">
                <img src={duplicateImg} alt={'Duplicar'}/>
                <img
                    src={deleteImg}
                    alt={'Excluir'}
                    onClick={()=>remove(data)}
                />
            </PageIcons>
            <PageDetail onClick={()=>{
                if(!disabled) setSelectedPage(data)
                }} />
            <div className ="pageNumber" style={{ position:'absolute', bottom:7, left:7, fontSize:14, color:"#323232" }}>
            {pageNumber}
            </div> 
           
        </Container>
        </div>
    )
}, (prevProps, nextProps) => {
    return (
        prevProps.remove === nextProps.remove &&
        prevProps.selectedPage === nextProps.selectedPage 
    );
});

export default PageThumb;
