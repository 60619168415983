import axios from "axios";
import Cookies from 'js-cookie';

const API = axios.create({
    // baseURL: 'http://192.168.0.13:3333'
    // baseURL: 'http://127.0.0.1:3333/'
    baseURL:"https://sensorieapp.com:3004/"
})

// ADICIONA O TOKEN A REQUISICAO
API.interceptors.request.use((config) => {

    const token = `Bearer ${Cookies.get('token_sensorie')}`;
    config.headers.Authorization = token;

    return config;
});

export default API;
