import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

`;

export const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    height: 19rem;
    width: 17rem;

    // border: solid;
    // border-color: #56179A;
    // border-width: 2px;
    border-radius: 25px;

    box-shadow: 1px 5px 5px #d9d9d9;
    z-index:1;

`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding:20px;
    width:100%;
`;

  export const Logo = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  align-self:center;
  background-color:white;
  position: absolute;
  border: solid;
  border-width:4px;
  border-color:white;

  animation: goUp 1.0s 0.5s;
  animation-fill-mode: both;

    @keyframes goUp {
  from {
    margin-bottom: 0rem;
  }
  to {
    margin-bottom:19rem;
  }
  }

  `

export const Input = styled.input`
    padding:5px;
    border: none;
    border-bottom: solid;
    border-bottom-width: 1.3px;
    border-bottom-color: #56179A;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size:14px;
    outline: none;
    color: #2E2E2E;

    &:focus{
       border-bottom-width: 2px;
   };


   animation: drawLine .9s 1.0s;
   animation-fill-mode: both;

     @keyframes drawLine {
   from {
     opacity:0;
     width: 0%;
   }
   to {
     opacity:1;
     width: 100%;
   }
  }

`;

  export const Footer = styled.div`

  width:100%;
  display:flex;
  flex-direction: row;
  bottom:10px;
  justify-content:space-between;
  align-items:center;
  align-content: flex-end;
  animation: emerge .9s 1.5s;
  animation-fill-mode: both;

  margin-top:20px;

    @keyframes emerge {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
 }

  `;