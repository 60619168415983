import { Container, BasicFilter, AdvancedFilter } from "./styles";
import searchImg from '../../../assets/images/search.svg'
import { useState } from "react";

const ExperimentListFilter = ()=>{

    const [ advancedFilterActive, setAdvancedFilterActive] = useState(false)

    return(
        <Container>
                <BasicFilter>
                    <div>
                        <img src={searchImg} alt="Pesquisar" />
                        <input type="text" placeholder="Busque por nome ou cliente" />
                        
                    </div>
                    <div>
                    <h5>Status</h5>
                        <select name="" id="">
                            <option value="">Todos</option>
                            <option value="">Aberto</option>
                            <option value="">Fechado</option>
                            <option value="">Rascunho</option>
                        </select>
                    </div>
                    <div>
                        <h5 onClick={()=>setAdvancedFilterActive(!advancedFilterActive)}>Filtro avançado {advancedFilterActive?"-":"+"} </h5>
                    </div>
                </BasicFilter>
                <AdvancedFilter isActive={advancedFilterActive}>
                    <h5>Criação</h5>
                    <input type="date" />
                    <input type="date" />
                </AdvancedFilter>
            
        </Container>
    )
}

export default ExperimentListFilter;