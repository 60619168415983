import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
:root{
    --red: #e52e4d;
    --blue: #5429cc;
    --blue-light: #6933ff;
    --text-title: #313131;
    --text-body: #969cb3;
    --background: #F0F2F5;
    --green: #33CC95;
    --shape: #FFFFFF;
    --purple-main: #56179a;
    --purple-second: #B89ED5;
    --purple-third: #F2EDF7;

}


 *{
    margin: 0 ;
    padding: 0;
    box-sizing: border-box;
 }

html{
    @media (max-width: 1080px){
        font-size: 93.75%;
    }
    @media (max-width: 720px){
        font-size: 87.5%;
    }
}

 body{
    background: #FFF;
    -webkit-font-smoothing: antialiased;
    scrollbar-width: thin;
    scrollbar-color: #888 #f5f5f5;

    ::-webkit-scrollbar{
        width:6px;
        }
        ::-webkit-scrollbar-track{
        background:#f7f7f7;
        border-radius:3px;
        }
        ::-webkit-scrollbar-thumb{
        background:var(--purple-second);
        border-radius:3px;
        }
 }

 body, input, textarea, button {
     font-family: 'Popins', sans-serif;
     font-weight: 400;
 }

 h1, h2, h3, h4, h5, h6, strong{
     font-weight: 600;
     color: var(--text-title);
 }

 button {
     cursor: pointer;
 }

 [disabled]{
     opacity: 0.6;
     cursor: not-allowed;
 }


 
.react-modal-overlay{
    background: rgba(0, 0, 0, 0.5);

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

}

.react-modal-content{
    width: 100%;
    max-width: 576px;
    max-width: 720px;
    background: var(--background);
    padding: 1.5rem;
    position: relative;
    border-radius: 0.25rem;

}

.react-modal-content-new-experiment{
    width: 100%;
    height: 100%;
    /* max-width: 576px;
    max-width: 720px; */
    background: var(--background);
    padding: 1.5rem;
    position: relative;
    border-radius: 0.25rem;

}

.react-modal-close{
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: 0;
    background: transparent;
    transition: filter 0.2s;

    &:hover{
        filter: brightness(0.8);
    }
}

`


