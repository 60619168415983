import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 3px;
    // background-color: #F2F2F2;
    height: 100%;
    width: 100%;
    overflow-y:auto;
    margin-bottom:7%;

    ::-webkit-scrollbar{
        width:6px;
        }
        ::-webkit-scrollbar-track{
        background:#f7f7f7;
        border-radius:3px;
        }
        ::-webkit-scrollbar-thumb{
        background:var(--purple-second);
        border-radius:3px;
        }
`;
