import React, {useRef, useCallback} from 'react';

import { useNavigate } from 'react-router-dom';
import {createRoot} from 'react-dom/client';
import { Container } from "./styles";
import { useModal } from '../../Pattern/Modal';
import { format } from 'date-fns'
import API from '../../../services/api';
import QRCode from "react-qr-code";
import {toPng} from 'html-to-image'
import downloadCustomExcel from '../../DownloadExcel';
import { transformDate, findDifferentSample } from '../../../services/helpers';
import { v4 as uuidv4 } from 'uuid';

import stopImg from '../../../assets/images/stop.svg'
import startImg from '../../../assets/images/start.svg'
import editImg from '../../../assets/images/edit.svg'
import deleteImg from '../../../assets/images/delete-grey.svg'
import qrCode from '../../../assets/images/qrCode.svg'
import downloadFile from '../../../assets/images/downloadFile.svg'
import whatsapp from '../../../assets/images/whatsapp.svg'
import email from '../../../assets/images/email.svg'
import download from '../../../assets/images/download.svg'
import duplicate from '../../../assets/images/duplicate.svg'

import Cookies from 'js-cookie';

const ExperimentListItem = ({data, reload, user, index})=>{
    
    const { id, name, created_at, status, hash, name_type} = data
    const navigate = useNavigate();
    const { addModal } = useModal();
    const ref = useRef(null);
   
    const onClickQr = useCallback(() => {
        if (ref.current === null) {
          console.error("Ref is null");
          return;
        }
      
        toPng(ref.current, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = `qrcodeExperiment-${id}.png`;
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.error(err);
        });
    }, [ref, id]);

    // ajustar o link
    // const link = `http://localhost:3001/answers/${hash}`

    const link = `https://sensorieapp.com/answers/${hash}`

    const whatsappLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(link)}`;

    const subject = 'Sensorie Answers';

    const emailLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(link)}`;

    function editExperiment(){

        // Adiciona o experimento nos cookies
        Cookies.set('sensorie-hash-experiment', hash);
        Cookies.set("sensorie-name-experiment", name);
        Cookies.set("sensorie-type-experiment", name_type);

        const toGo = `/newexperiment/${hash}/${name_type}`;
        navigate(toGo);
    }

    async function stopExperiment(){
        const confirmation = window.confirm("Deseja parar a execução do experimento?")
        if(confirmation){
            await API.put('experiments/'+id,{status:"STOPPED"})
            reload()
          }
    }

    async function launchExperiment(){
        const confirmation = window.confirm("Deseja iniciar a execução do experimento?")
        if(confirmation){
            await API.put('experiments/'+id,{status:"RELEASED"})
            reload()
          }
    }

    async function deleteExperiment(){
        const confirmation = window.confirm("Deseja mesmo deletar o experimento?")
        if(confirmation){
            await API.delete('experiments/'+ id)
            reload()
        }
    }

    function showQRCode(){
        addModal({   
        body: 
        <div style={{display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center'}}>
            <div 
                ref={ref}
            >
                <QRCode
                    style={{marginTop:'.8rem'}}
                    value={link} 
                />
            </div>
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                <label>Link para compartilhar</label>
                <a style={{textDecoration:'none'}} href={link} target="_blank" rel="noopener noreferrer">sensorie-answers.com</a>

            </div>
            <div style={{display:'flex', flexDirection: 'row', justifyContent:'space-evenly',alignItems:'center', width:'100%'}}>
                <div style={{display:'flex'}}>
                    <a href={whatsappLink} target="_blank" rel="noopener noreferrer"><img src={whatsapp} alt='whatsapp'/></a>
                </div>
                <div style={{display:'flex'}}>
                    <a href={emailLink} target="_blank" rel="noopener noreferrer"><img src={email} alt='email'/></a>
                </div>
                <div>
                    <a href onClick={()=>{onClickQr()}} target='_blank' rel="noopener noreferrer"><img src={download} alt='download'/></a>
                </div>
            </div>
        </div>
        ,
          buttonPrimary: { label:"Fechar" }})
    }

    function countEqualDates(answers) {
        return answers.reduce((dateCounts, answer) => {
          dateCounts[answer.date] = (dateCounts[answer.date] || 0) + 1 ;
          return dateCounts;
        }, {});
    }

    function duplicateExperiment(){
        API.post('/duplicateExperiment/'+id,{hash:uuidv4(),user_id:user}).then((res)=>{
            if(res.status == 200){
                return reload()
            }
        })
    }
      
   async function downloadExcel(){
        
        // Amostras
        //projeto - data e hora - avaliador - amostra - respostas
        let data = [];
        let columns = name_type == "Triangle" ? ['Projeto','Data e hora', 'Avaliador','Sequência', 'Resposta correta']: ['Projeto','Data e hora', 'Sequência', 'Amostra'];
        let finalData = []
        
        // dados extras
        // projeto - data e hora - avaliador - respostas
        let columnsExtra = ['Projeto','Data e hora','Avaliador']
        let finalExtraData = []

        // Triangular
        if(name_type === "Triangle"){
            await API.get('answersTriangle/'+ id).then((res)=>{
                if(res.data && res.data.answers.length > 0 && res.data.questions.length> 0){
                    const {answers,questions,samples} = res.data
                    // console.log(samples)
                    questions.sort((a,b)=>a.order - b.order);

                    questions.forEach((item)=>{
                        columns.push(item.questions)
                    })

                    answers.sort((a, b) => new Date(a.date) - new Date(b.date));

                    answers.sort((a,b) => {return a.item_order - b.item_order})

                    const groupedData = answers.reduce((acc, obj,index) => {

                        const { experiment_name, date, sequence_code, sequence, sample_cod, answer } = obj;
                        const sampleKey = `${experiment_name}_${date}_${sequence_code}_${sample_cod}`; 
                          let consumer = index + 1
                          if (!acc[sampleKey]) {
                              acc[sampleKey] = {
                              experiment_name,
                              date,
                              consumer,
                              sequence_code,
                              sample_cod,
                              sequence,
                              answers: [] 
                              };
                          }
          
                          acc[sampleKey].answers.push({ [`Item_${acc[sampleKey].answers.length + 1}_${date}`]: answer });
                          return acc;
          
                      }, {});

                      for (let key in groupedData) {
                       
                        const different = findDifferentSample(samples,groupedData[key].sequence.split('##'))
                        
                        let splitSequence = groupedData[key].sequence.split('##')
                        
                        if(!splitSequence.includes(String(groupedData[key].sample_cod))){
                            groupedData[key].sample_cod = parseInt(different.second_code)
                        }

                        const rowData = [
                          groupedData[key].experiment_name,
                          transformDate(String(groupedData[key].date)),
                          groupedData[key].consumer,
                          groupedData[key].sequence.replaceAll('##','-'),
                          groupedData[key].sample_cod,
                          ...Object.values(Object.assign({}, ...groupedData[key].answers)) 
                        ];
          
                        data.push(rowData);
                      }

                    finalData = data
                }else window.alert("Nenhum formulário foi respondido ainda.")


                
            })
        }else{
            // Amostras e perguntas
            await API.get('answersSampleByExperiment/' + id).then((res) => {
    
                if(res.data && res.data.answers.length > 0 && res.data.questions.length > 0){
                    const {answers, questions} = res.data
                    
                    questions.sort((a, b) => a.order - b.order);
    
                    questions.forEach((item) => {
                        columns.push(item.questions);
                    });
                    
                    // ordenei por data, agora falta verificar se existe a quantidade de respostas iguais a da sequencia se não existir
                    let dateCounts = countEqualDates(answers);
                    const countQuestions = questions.length
                    const countSamples = answers[0].sequence.split("##").length
                    
                    const dateBelowThreshold = Object.entries(dateCounts).filter(([date, count]) => 
                        count < countSamples*countQuestions
                    );

                    // verificar quais são os arrays que faltam respostas e quantas responstas faltam da sequência
                    const missingAnswers = dateBelowThreshold.reduce((acc, [date, count]) => {
                        const answersForDate = answers.filter((answer) => answer.date == date);

                        const sequence = answersForDate[0].sequence
                        const sequenceAnswers = sequence.split('##')
                        let aux = []

                        sequenceAnswers.map((item)=>{
                            let answerAux = answersForDate.filter((answer)=>{ return item == answer.sample_cod})
                            if(answerAux.length < questions.length){
                                aux.push(item)
                            }

                        })

                      for(let x = 0; x < questions.length; x++){
                          acc.push(...aux.map((code, index) => ({
                            experiment_name: answersForDate[0].experiment_name,
                            date,
                            item_id: answers[answers.length - 1].item_id + index + 1,
                            sequence_code: answersForDate[0].sequence_code,
                            sequence: answersForDate[0].sequence,
                            question: questions[x].questions,
                            sample_cod: code, 
                            answer: '', 
                          })));
                         
                      }
                        return acc;
                      }, []);
                      
                      let finalAnswers = []
                      missingAnswers.map((item)=>{
                        finalAnswers.push(item)
                      })
                      answers.map((item)=>{
                        finalAnswers.push(item)
                      })

                      finalAnswers.sort((a, b) =>{
                        return new Date(a.date) - new Date(b.date)});

                      finalAnswers.sort((a,b) =>{
                        return a.item_order - b.item_order;
                      })
                      
                    
                const groupedData = finalAnswers.reduce((acc, obj) => {
                  const { experiment_name, date, sequence_code, sequence, sample_cod, answer } = obj;
                  const sampleKey = `${experiment_name}_${date}_${sequence_code}_${sample_cod}`; 
                    if (!acc[sampleKey]) {
                        acc[sampleKey] = {
                        experiment_name,
                        date,
                        sequence_code,
                        sample_cod,
                        sequence,
                        answers: [] 
                        };
                    }
    
                    acc[sampleKey].answers.push({ [`Item_${acc[sampleKey].answers.length + 1}_${date}`]: answer });
                    return acc;
    
                }, {});
    
                for (let key in groupedData) {
                  const rowData = [
                    groupedData[key].experiment_name,
                    transformDate(String(groupedData[key].date)),
                    groupedData[key].sequence_code,
                    groupedData[key].sample_cod,
                    groupedData[key].sequence,
                    ...Object.values(Object.assign({}, ...groupedData[key].answers)) 
                  ];
    
                  data.push(rowData);
                }
    
                let qttSequence = data[0][4].split('##')
    
                let sequenceLength = qttSequence.length         
    
                let sequences = []
    
                data.map((item,index)=>{
                    if (item !== sequences[index]){
                       sequences.push(item[4]) 
                    }
                })
    
                let sequenceAux = []
                for(let x = 0; x <= sequences.length - 1; x = x + sequenceLength){
                    sequenceAux.push(sequences[x])
                }
    
                const transformArray = (item) => {
                    const numbers = item.split('##').map(num => Number(num));
                    return numbers;
                  };
    
                  const transformedArray = sequenceAux.map(transformArray);
                  
                  transformedArray.map((orderIDs, index) => {
                    const adjustedOrder = orderIDs.map((orderId) => {
                    const foundItemIndex = data.findIndex(item => item[3] == orderId);
                
                    if (foundItemIndex !== -1) {
                        const foundItem = data[foundItemIndex];
                        foundItem[2] = index + 1
                        finalData.push(foundItem);
                        data.splice(foundItemIndex, 1);
                    }
                
                    return orderId;
                    });
                
                    return adjustedOrder;
                });
    
                finalData = finalData.map(subarray => {
                    subarray.splice(4, 1); 
                    return subarray;
                  });

                }else window.alert("Nenhum formulário foi respondido ainda.")
    
            });
    
        }
        
        //   Dados extras            
        await API.get('/answersExtraExperiment/'+id).then((res)=>{
            const {answers, questions} = res.data
            // questoes colocadas em um array
            
            questions.sort((a, b) => a.order - b.order);

            questions.forEach((item) => {
                columnsExtra.push(item.questions);
            });

            const groupedData = answers.reduce((acc, obj) => {
                const { experiment_name, date, sequence_code, question, answer } = obj;
                const sampleKey = `${experiment_name}_${date}_${sequence_code}`; 
                if (!acc[sampleKey]) {
                  acc[sampleKey] = {
                    experiment_name,
                    date,
                    sequence_code,
                    answers: [] 
                  };
                }
                acc[sampleKey].answers.push({ [`Item_${question}`]: answer }); 
                return acc;
              }, {});

              const rows = Object.values(groupedData).map((entry, index) => {
                const row = [entry.experiment_name, transformDate(String(entry.date)), index + 1];
            
                questions.forEach((item) => {
                    const questionKey = `Item_${item.questions}`;
                    const answer = entry.answers.find((ans) => questionKey in ans);
                    row.push(answer ? answer[questionKey] : ''); // Se não houver resposta, adiciona uma string vazia
                });
            
                return finalExtraData.push(row);
            });

        })

        // Amostras
          if(finalData && finalData.length != 0){
            const dataSheetSamples = {
                columns:columns,
                data:finalData,
                sheetName: 'Amostras'
            }
            // Extra
            const dataSheetSocial = {
                columns:columnsExtra,
                data:finalExtraData,
                sheetName: 'Dados Extras'
            }

            downloadCustomExcel([dataSheetSamples,dataSheetSocial], `respostas-${name}.xlsx`)
        }
    }

    return(
        <Container>
            <div>
                <p>{ name }&nbsp;{String(name) === "Novo Experimento"?parseInt(index) === 0 ? "" : index :""}</p>
            </div>
            <div>
                <p style={{ fontSize:13 }}>Criação</p>
                <p style={{ fontSize:14 }}>{ format(new Date(created_at),'dd/MM/yyyy') }</p>
            </div>
            <div>
                <p style={{ fontSize:13 }}>Status</p>
                <p style={{ fontSize:14 }}>{ status==='CREATED'?'Rascunho':status==='RELEASED'?'Lançado':'Fechado' }</p>
            </div>
            <div style={{display:'flex', justifyContent:'space-evenly', border:"solid", borderWidth:"0px 0px 0px 1.5px", borderColor:"#4e2394"}}>
                {/* fazer duplicar o experimento, ver prioridade disso */}
                <img src={duplicate} alt="Duplicar" onClick={duplicateExperiment} />
                {
                    // alterar o caminho para o formulario
                    //criar um modal para mostrar o link e o qrcode para acessar o link do formulario
                    // status==='RELEASED'&&<a target={'blank'} href={'/answer/'+hash}><img src={shareImg} alt="Compartilhar" /></a>
                }
                {
                    // status!=='CREATED'&&<img src={infoImg} alt="Informações" />
                }
                {
                    status==='RELEASED'&&<img onClick={stopExperiment} src={stopImg} alt="Parar" />
                }
                {
                    status==='STOPPED'&&<img onClick={launchExperiment} src={startImg} alt="Iniciar" />
                }
                {
                    status==='RELEASED'&&<img src={qrCode} onClick={showQRCode} alt='QRCode link'/>
                }
                {
                    status==='RELEASED' || status==='STOPPED'?<img src={downloadFile} onClick={downloadExcel} alt='Download Excel'/>:<></>
                }
                <img src={editImg} alt="Editar" onClick={editExperiment} />
                {
                    status==='RELEASED'?
                    <img  src={deleteImg} alt="Deletar" disabled style={{display:'none'}}/>
                    :
                    <img  src={deleteImg} alt="Deletar" onClick={deleteExperiment}/>
                }
                
            </div>
            
        </Container>
    )
}

export default ExperimentListItem;
