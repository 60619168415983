import React from 'react';

export function truncate(string, len){

  if(string && string.length > len){

      string = string.slice(0,len - 3) + "...";
  }

  return string;
}

export function transformDate(string){
  const dataObj = new Date(string);
  
  // Obtendo dia, mês, ano, hora e minutos
  const dia = String(dataObj.getDate()).padStart(2, '0');
  const mes = String(dataObj.getMonth() + 1).padStart(2, '0');
  const ano = dataObj.getFullYear();
  const hora = String(dataObj.getHours()).padStart(2, '0');
  const minutos = String(dataObj.getMinutes()).padStart(2, '0');
  
  // Montando a string formatada
  const dataFormatada = `${dia}/${mes}/${ano} - ${hora}:${minutos}`;
  
  return dataFormatada;
}

// export function soma(x,y){
//   var resultado = x + y;
//   return resultado;
// }
//
// <button onClick={()=> soma(x,y)}>Fazer a conta</button>

export function findDifferentSample(sam, seq){
  let differentSample = null;

  sam.forEach(s => {
      
      const isFirstInSeq = seq.includes(String(s.cod));
      const isSecondInSeq = seq.includes(String(s.second_code));

      // Verifica se exatamente um dos números está presente na sequência
      if ((isFirstInSeq && !isSecondInSeq) || (!isFirstInSeq && isSecondInSeq)) {
          differentSample = s;
      }
  });

  return differentSample;
}
