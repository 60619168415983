import { useState, useRef, useEffect } from 'react'
import { Container,
         ItemBoxOptions,
         MainQuestionPart,
         VariantQuestionPart ,
         RemoveItemLink,
         ArrTop,
         ArrDown,
         ParagraphInput,
         StyledTextarea
        } from './styles'

import Switch from 'react-input-switch'

import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

import { FaRegCircle, FaRegDotCircle } from "react-icons/fa"

import duplicateImg from "../../../assets/images/duplicate-thumb.svg"
import deleteImg from "../../../assets/images/delete-thumb.svg"
import moreVertImg from "../../../assets/images/more-vert.svg"
import API from '../../../services/api';


export const ItemBox = ({data, updateItemOnPage, arrayIndex, upQuestion, downQuestion, isLast, removeItemFromPage, setFocusOnEnd, activePage})=>{

const [onHover, setOnHover] = useState(false)
const [mandatory, setMandatory] = useState(data.mandatory?"Obrigatório":"Não obrigatório");
const [questionType, setQuestionType] = useState(data.type)
const [questionDescription, setQuestionDescription ] = useState(data.description)
const [linearRange, setLinearRange] = useState(null);
const [headText, setHeadText] = useState(data.options==='head'?true:false)
const [youtubeId, setYoutubeId] = useState('')
const [paragraphContent, setParagraphContent] = useState(data.description)
const [imgUrl, setImgUrl] = useState('')

const textareaRef = useRef(null);

const [ questionOptions, setQuestionOptions ] = useState(data.options && data.type != 'linear'?data.options.split("&&"):[''])


useEffect(()=>{
    mandatoryItem(data,mandatory=="Obrigatório"?true:false)
},[mandatory])

async function mandatoryItem(params, mandatory) {
    await API.put('/items/'+ params.id, {mandatory: mandatory})
}

async function removeOption(index){

    setQuestionOptions(state => {
      let rest = [...state];
      rest.splice(index,1);
      return rest;
    });

 }

 async function removeItem(){

    const confirmation = window.confirm("Deseja realmente excluir?")
    if(confirmation){

        if(data.type==='image'){
            await API.post('/deleteobjects3',{
                fileName: data.description
              })
        }
        await API.delete('items/'+data.id)
        removeItemFromPage(data.id)
    }

 }

 async function saveParagraph(){

    await API.put('items/'+data.id,{description:paragraphContent, options:headText?"head":"regular"})

 }

 async function saveHeadParagraph(value){


    await API.put('items/'+data.id,{ options:value?"head":"regular" })

    setHeadText(value)

 }

 async function duplicateItem(){

    let response = await API.get('duplicateitem/' + data.id)
    let ap = {...activePage}
    ap.items.push(response.data.item)
    updateItemOnPage(ap)
    setFocusOnEnd()

 }


 function handleYoutubeLink(){
    verifyLink()
    updateItem('description')
 }

 function verifyLink(){

    if(questionDescription !== null && questionDescription !== undefined){

        const split4 = questionDescription.replaceAll('watch?v=', '$').replaceAll('&ab_channel', '$').split('$');

        setYoutubeId(split4[1])
    }
 }

 // TYPE linear
 // updateItem("type") -> linear
 // true true 
 // apaga os dados das options

 function setItemOnPage(newData){
    let ap = {...activePage};
    ap.items[arrayIndex] = { ...ap.items[arrayIndex], ...newData}
    updateItemOnPage(ap);
 }

 async function updateItem(property){

    if(property==='type'){

        if(questionType !== 'select' || questionType !== 'multiple'){

            let options = questionType == 'linear' && linearRange?
            `${linearRange.min}#${linearRange.max}#${linearRange.step}#${linearRange.textMin}#${linearRange.textMax}`
            :
            questionType == 'linear'?'#clearAll##':questionOptions.join('&&')
            
            await API.put('items/'+data.id,{ 
                type:questionType, 
                options:options
            })
            .then(()=>{
                setItemOnPage({ type:questionType, options:options })
            })

            if(questionOptions.length == 0){
                setQuestionOptions([""])
            }
        }else{
            await API.put('items/'+data.id,{type:questionType})
            .then(()=>setItemOnPage({ type:questionType }))
        }

    }else if(property==='description'){
        await API.put('items/'+data.id,{description:questionDescription})
        .then(()=>setItemOnPage({ description:questionDescription }))

    }else if(property==='options'){
        await API.put('items/'+data.id,{options:questionOptions.join('&&')})
        .then(()=>setItemOnPage({ options:questionOptions.join('&&') }))
    }
  }

  useEffect(()=>{
    if(questionType === 'linear'){
        let payload = {};

        if(!linearRange && data){
        payload = { 
            min:0, 
            max:100, 
            step:1, 
            value:0, 
            textMin:(data.options == null ||  data.options == "") && data.type != 'linear'? '' : data.options.split('#')[3],
            textMax:(data.options == null ||  data.options == "") && data.type != 'linear'? '' : data.options.split('#')[4]
        }
        setLinearRange(payload);
        saveLinearData(payload);
        }

    }
  },[questionType])

  async function saveLinearData(l){

    await API.put('items/'+ data.id, {options:`${l.min}#${l.max}#${l.step}#${l.textMin}#${l.textMax}`})
    .then((res)=>{
        setItemOnPage({options:`${l.min}#${l.max}#${l.step}#${l.textMin}#${l.textMax}`})
    })
    .catch((err)=>console.log("error"))

  }

  async function setLinearRangeOptions(field, value){

    let linearInfos = {...linearRange};
    linearInfos[field] = value;
    saveLinearData(linearInfos)
    setLinearRange(linearInfos);

  }

  async function prepareImageLink(){

    const   fileUrl   = await API.post('/generatedownloadlink',{
        fileName: data.description,
      })

      setImgUrl(fileUrl?.data)
  }

  useEffect(()=>{
    if(questionType !== data.type){
        updateItem('type');
        data.type = questionType;
    }
  },[questionType])

  useEffect(()=>{
    updateItem('options');
  },[questionOptions])

  useEffect(()=>{
    verifyLink();
  },[questionDescription])

  useEffect(()=>{
    if(data.type==='image'){
        prepareImageLink()
    }
  },[])

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setQuestionDescription(value);
    adjustTextareaHeight();
  };


return(
    <Container onMouseEnter={()=>setOnHover(true)} onMouseLeave={()=>setOnHover(false)}>

        <MainQuestionPart>
        <ArrTop disabled={data.order==1}
                isOnHover={onHover}
                onClick={()=>data.order>1?upQuestion(data.id):null}
        >
            <IoIosArrowUp />
        </ArrTop>

        <ArrDown disabled={isLast}
                 isOnHover={onHover}
                 onClick={()=>isLast?null:downQuestion(data.id)}
        >
            <IoIosArrowDown />
        </ArrDown>
            
            <div className={questionType=='video'?'w100PerCent':'w60PerCent'}>
                {(questionType=='paragraph' || questionType=='image')
                ?null
                :questionType=='video'
                ?<input type="text" placeholder='Link do YouTube' value={questionDescription} onChange={e=>setQuestionDescription(e.target.value)} onBlur={handleYoutubeLink} />
                :
                <>
                {
                    questionType==='longtext'
                    ?
                    <StyledTextarea
                        ref={textareaRef}
                        placeholder='Digite aqui a pergunta' 
                        value={questionDescription} 
                        onChange={e=>handleInputChange(e)} 
                        onBlur={()=>updateItem('description')}
                    />
                    
                    :
                    <input type="text" placeholder='Digite aqui a pergunta' value={questionDescription} onChange={e=>setQuestionDescription(e.target.value)} onBlur={()=>updateItem('description')}/>
                }
                </>
                }

            </div>

            {
            // questionType==='image'
            // ?<img
            //     className='imgItem'
            //     src='https://img.freepik.com/fotos-gratis/figura-triangular-geometrica-legal-em-uma-luz-de-laser-neon-otima-para-fundos-e-papeis-de-parede_181624-9331.jpg?w=2000'
            // />

            // :
            questionType=='paragraph'
            ?
                <span className='titleOption'>
                    {
                        headText
                        ?<FaRegDotCircle onClick={()=>saveHeadParagraph(false)} />
                        :<FaRegCircle onClick={()=>saveHeadParagraph(true)} />
                    }

                    <p>Título</p>
                </span>
            :
            
            (questionType!='video' && questionType!='paragraph' && questionType!='image')
            
            ?
                <div className={"w30PerCent"} >
                    <select value={questionType} name="" id="" onChange={e=>setQuestionType(e.target.value)}>
                        <option value={0}>Tipo de resposta</option>
                        <option value={'text'}>Texto curto</option>
                        <option value={'longtext'}>Texto longo</option>
                        <option value={'multiple'}>Múltipla escolha</option>
                        <option value={'select'}>Caixas de seleção</option>
                        <option value={'linear'}>Escala linear</option>
                        <option value={'date'}>Data</option>
                    </select>
                </div>
            :
                null
            }

        </MainQuestionPart>


        <VariantQuestionPart>

            {questionType==='text'&&
            <>
                <p>O participante verá um campo assim para responder:</p>
                <input type="text" width={100} disabled />
            </>}

            {questionType=='longtext'&&
            <>
                <p>O participante verá um campo assim para responder:</p>
                <textarea name="" id="" cols="30" rows="5" disabled></textarea>
            </>}

            {questionType=='multiple'&&
            <>
              {questionOptions.map((question,index)=>(

                <OptionQuestion
                  key = {`${question}${index}`}
                  index = {index}
                  question = {question}
                  remove = {removeOption}
                  // autofocus = {question === '' && index === (question.length -1)}
                  questionOptions = {questionOptions}
                  setQuestionOptions = {setQuestionOptions}
                />
              )
              )}
              <br/><br/>
              <p onClick={()=>setQuestionOptions(state => ([...state, ""]))}>Adicionar opção</p>

            </>
            }

            {questionType=='select'&&
            <>

                {questionOptions.map((question, index)=>(
                <CheckboxQuestion 
                    index={index}
                    key={`${question.id}${index}`}
                    question={question}
                    remove={removeOption}
                    questionOptions = {questionOptions}
                    setQuestionOptions = {setQuestionOptions}
                />)
                )}
                <br /><br />
                <p onClick={()=>setQuestionOptions([...questionOptions,''])}>Adicionar opção</p>

            </>
            }

            {questionType==='linear'&& linearRange?
            <div key={`${data.type}${data.id}`}>

                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                    <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
                        <div style={{ marginRight: '1rem', textAlign:'center' }}>
                            <p style={{ whiteSpace: 'nowrap', marginTop:'1rem', marginRight:'0.5rem' }}>Min</p>
                            <input type="text" value={linearRange.min} onChange={(e) => setLinearRangeOptions('min',e.target.value)}/>
                        </div>
                        <div style={{ marginRight: '1rem' }}>
                            <p style={{ whiteSpace: 'nowrap', marginTop:'1rem', marginRight:'0.5rem' }}>Máx</p>
                            <input type="text" value={linearRange.max} onChange={(e) => setLinearRangeOptions('max',e.target.value)}/>
                        </div>
                        <div>
                            <p style={{ whiteSpace: 'nowrap', marginTop:'1rem', marginRight:'0.5rem' }}>Passo</p>
                            <input type="text" value={linearRange.step} onChange={(e) => setLinearRangeOptions('step',e.target.value)}/>
                        </div>
                    </div>
            
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginBottom: '0.5rem' }}>
                            <p style={{ whiteSpace: 'nowrap', marginTop:'1rem', marginRight:'0.5rem' }}>Rótulo Mínimo:</p>
                            <input type="text" value={linearRange.textMin == 'undefined' ? '' : linearRange.textMin} onChange={(e) => setLinearRangeOptions('textMin',e.target.value)}/>
                        </div>
                        <div>
                            <p style={{ whiteSpace: 'nowrap', marginTop:'1rem', marginRight:'0.5rem' }}>Rótulo Máximo:</p>
                            <input type="text" value={linearRange.textMax == 'undefined'? '' : linearRange.textMax} onChange={(e) => setLinearRangeOptions('textMax',e.target.value)}/>
                        </div>
                    </div>
                </div>
            
                {/* <p>O participante verá um campo assim para responder:</p>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <p>{linearRange.value}</p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginRight: '0.5rem' }}>{linearRange.textMin}</p>
                        <input type="range" value={linearRange.value} min={linearRange.min} max={linearRange.max} step={linearRange.step} onChange={(e) => setLinearRange({ ...linearRange, value: e.target.value })} />
                        <p style={{ marginLeft: '0.5rem' }}>{linearRange.textMax}</p>
                    </div>
                </div> */}
            </div>
            :
            <></>
            }

            {questionType=='date'&&
            <>
                <p>O participante verá um campo assim para responder:</p>
                <input type="date" width={100} disabled />
            </>}

            {questionType=='video'&&
            <>
                {/* <p>Digite o link do Youtube:</p>
                <input type="text" width={100}  /> */}
                {youtubeId
                ?<YoutubeEmbed embedId={youtubeId} />
                :null}

            </>}

            {questionType=='paragraph'&&
            <>
                <ParagraphInput
                    headText={headText}
                    className='paragraphInput'
                    rows="10"
                    defaultValue={paragraphContent}
                    onChange={(e)=>setParagraphContent(e.target.value)}
                    onBlur={saveParagraph}
                />

            </>}

            {questionType=='image'&&
            <img
                className='imgItem'
                src={imgUrl}
            />
            }

        </VariantQuestionPart>


        <ItemBoxOptions isOnHover={onHover}>
            <div>
                {mandatory}
                <Switch on='Obrigatório' off='Não Obrigatório' value={mandatory} onChange={setMandatory} />
            </div>
            <div>
                <img src={duplicateImg} onClick={duplicateItem} alt="Duplicar" />
                <img src={deleteImg} onClick={removeItem} alt="Deletar" />
                <img src={moreVertImg} alt="Mais" />
            </div>
        </ItemBoxOptions>
    </Container>
)
}

export const OptionQuestion = ({ remove, question, index, setQuestionOptions, questionOptions })=>{

    const [showDeletOption, setShowDeletOption] = useState(0);

    useEffect(()=>{
    },[questionOptions])

    function alterOption(value){

        if(questionOptions.length>1){

            setQuestionOptions(state => {
              let newArr = [...state];
              newArr[index] = value;
              return newArr;
            })

        }else{
            setQuestionOptions([value])
        }
    }

    return(

        <div onMouseEnter={()=>setShowDeletOption(question)} onMouseLeave={()=>setShowDeletOption(0)}>

            <input className='smallRadio' type="radio"/>

            <input
             className='optionWidth'
             type="text"
             defaultValue = {question}
             placeholder='Digite a opção'
             onBlur={(e) => alterOption(e.target.value)}
            />

            <RemoveItemLink
             isVisible = {showDeletOption === question}
             onClick = {()=>remove(index)}
            >Remover
            </RemoveItemLink>
        </div>

    )
}

export const CheckboxQuestion = ({ remove, question, index, setQuestionOptions, questionOptions })=>{

    const [showDeletOption, setShowDeletOption] = useState(0)

    function alterOption(value){

        if(questionOptions.length>1){
            const newArr = [...questionOptions]
            newArr[index] = value

            setQuestionOptions(newArr)

        }else{
            setQuestionOptions([value])
        }
    }

    return(

        <div onMouseEnter={()=>setShowDeletOption(question)} onMouseLeave={()=>setShowDeletOption(0)}>
            <input className='smallRadio' type="checkbox"  />
            <input className='optionWidth' type="text"
                    defaultValue={question}
                    placeholder='Digite a opção'
                    onBlur={(e)=>alterOption(e.target.value)}
            />
            <RemoveItemLink
                isVisible={showDeletOption===question}
                onClick={()=>remove(index)}
            >Remover</RemoveItemLink>
        </div>

    )
}

export const YoutubeEmbed = ({embedId}) => {

    return(
        <div className="video-responsive">
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
    )
    };
