import logoImg from '../../assets/images/logo.svg'
import { Container, Logo } from './styles'

const Header = ()=>{

    return(
        <>
            <Container>
                <Logo className='testanimation'>
                    <img className='testanimation' src={logoImg} alt="Sensorie"/>
                </Logo>

            </Container>
        </>
    )
}

export default Header;
