import React, { useState, useEffect, useRef } from 'react';
import { CustomButtomBordered, Input, BallButton, Select } from '../styles';

import { ListItem, ScroolView, ScrollableDiv } from './styles';
import deleteImg from '../../../assets/images/delete-grey.svg'

import API from '../../../services/api';

import { truncate } from "../../../services/helpers";

import datasheetImg from '../../../assets/images/datasheet.svg'

import * as XLSX from 'xlsx';

function SampleAndSequence(props){

  const {
    setSamplesOnParent,
    setSequencesOnParent,
    samplesOnParent,
    sequencesOnParent,
    activePage = 0,
    saveExperiment,
    type
  } = props;

  const [selectedPage, setSelectedPage] = useState(activePage);
  const [samples, setSamples] = useState(samplesOnParent);
  const [selects, setSelects] = useState([]);
  const [sequences, setSequences] = useState(sequencesOnParent);
  const [error, setError] = useState('');
  const [selectedSamples, setSelectedSamples] = useState([]);
  const [currentData, setCurrentData] = useState({cod:'', name:'', obs:'', second_code:'' });
  const [experiment, setExperiment] = useState(props.experiment);

  const ref = useRef(null);

  useEffect(()=>{
    if(selectedPage === 0 && ref !== null){
      ref.current.focus();
    }
  },[ref, selectedPage])

  useEffect(()=>{

    initialSampleState()

  },[selectedPage])

  const [file,setFile] = useState(null);
  const [extractedData, setExtractedData] = useState(null);

  useEffect(()=>{
    if(file) handleFile(file);
  },[file])

  function handleFile(file) {

    const { files } = file.target;
    const fileReader = new FileReader();
    let data = [];

    fileReader.onload = async (event) => {
      let rows = [];
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });

        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            rows = rows.concat(
              XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
            );
          }
        }
      } catch (e) {
        window.alert('Erro ao ler a planilha')
      }

     let currentExperiment = experiment;
     if(experiment.id == 0) currentExperiment = await saveExperiment();

      for(let row of rows){
        let formatted = {
          name:null,
          seqs: []
        };

        Object.keys(row).map((l,id)=>{
          if(id === 0) formatted.name = row[l];
          else formatted.seqs.push({ 
            cod: row[l], 
            name:"", 
            obs:"", 
            experiment_id: currentExperiment.id 
          });
        })
        data.push(formatted);
      }

     let samplesFromFile = data[0].seqs;

     await API.post('savesamplesfile', { samplesFromFile })
     .then((res)=>{

        setError('');
        setSamples(res.data.samples)
        setSamplesOnParent(res.data.samples);
        ref.current.focus();
     })

     let sequencesFromFile = data.map((s)=>({
      name: s.name,
      experiment_id: currentExperiment.id,
      sequence:s.seqs.map((v)=> v.cod).join("##")
     }))

     await API.post('savesequencesfile', { sequencesFromFile  })
     .then((res)=>{

      setSequences(res.data.sequences);
      setSequencesOnParent(res.data.sequences);
      initialSampleState();
     })

      // setSamplesOnParent(data[0].seqs);
      // setSamples(data[0].seqs)
      // setSequencesOnParent(data);

      // setSequences(data);
      setExtractedData(data);

    };

    if (files[0] !== undefined) fileReader.readAsBinaryString(files[0]);
  }

  function initialSampleState(){

    if((samples.length > 0) && (selectedPage === 1)){
      let vet = [];
    samples.map((item, index)=>{
      vet.push({
        select: index,
        values:[...samples].map((s)=>({ cod: s.cod, active: true })),
        selectedSample: ""
      })
    })
    setSelects(vet);
    }

  }

   async function saveSampleOnDb(currentData){

    try {
      if(experiment.id !== 0){
        await API.get(`getCodSample/${currentData.cod}/${experiment.id}`).then(
          async (res) =>{
            if (!res.data){
              currentData.experiment_id = experiment.id
              
              await API.post('samples',currentData)
              .then((res)=>{

                setError('');

                currentData.id = res.data.sample.id
                
                setSamples([...samples, currentData])
                setSamplesOnParent([...samples, currentData]);
                setCurrentData({ cod:'', name:'', obs:'', second_code:'' })
                ref.current.focus();
              })
            }else{
              setError('Essa amostra já existe!');
              setCurrentData({cod:'',name: currentData.name, obs: currentData.obs, second_code: ''})
            }
          }
        )
      }else{
         await saveExperiment().then(async (res)=>{
          setExperiment(res)
          
          const data = {
            cod: currentData.cod,
            second_code: currentData.second_code,
            name: currentData.name,
            obs: currentData.obs,
            experiment_id: res.id
          }
          await API.post('samples', data)
          .then((res)=>{
            currentData.id = res.data.sample.id
            setSamples([...samples,currentData])
            setSamplesOnParent([...samples,currentData]);
            setCurrentData({ cod:'', name:'', obs:'', second_code:''})
            ref.current.focus();
            console.log('Salvo com sucesso!')
          })
         });
      }
    } catch (error) {
      console.log('error on save Sample', error)
    }
   }

  function addNewSample(){
    if(type == 'Triangle'){
      if(currentData.cod !== '' && currentData.name !== '' && currentData.second_code !== ''){
        if(samples.length < 2){
          let newValue = [...samples, currentData]
          saveSampleOnDb(currentData, newValue);
        }else{
          alert("Você só pode adicionar até dois tipos de amostras!");
          setCurrentData({cod: '', name: '', second_code: ''})
        }
      }
    }else{
      if(currentData.cod !== '' && currentData.name !== ''){
  
        let newValue = [...samples, currentData]
  
        saveSampleOnDb(currentData, newValue);
  
      }
    }

  }

  useEffect(()=>{
    console.log('samples')
    if(samples.length == 2 && type == 'Triangle'){
      if(sequences.length == 0){
        createSequences()
      }
    }else if(samples.length < 2 && type == 'Triangle'){
      if(sequences.length>0){
        deleteSequences()
      }
    }
  },[samples])


  function createSequences(){

    let cod1 = parseInt(samples[0].cod)
    let secondCode1 = parseInt(samples[0].second_code)
    let cod2 = parseInt(samples[1].cod)
    let secondCode2 = parseInt(samples[1].second_code)

    let auxSequences = [
      [cod1,secondCode1,cod2],[secondCode1,secondCode2,cod1],[cod2,cod1,secondCode1],
      [cod2,secondCode2,cod1],[secondCode2,secondCode1,cod2],[cod1,cod2,secondCode2]
    ]
    auxSequences.map((item,index)=>{
      saveSequence(index + 1,item)
    })
  }

  function deleteSequences(){
    sequences.map((item)=>{
      deleteSequenceTriangle(item)
    })
    setSequences([]);
    setSequencesOnParent([]);
  }

  function selectSample(selectId, value){

    let allSelects = [...selects];

    let oldValue = allSelects[selectId].selectedSample;

    if(oldValue !== "" && oldValue !== value)
    {
      oldValue = allSelects[selectId].selectedSample;
      allSelects[selectId].selectedSample = value;
    }

    if(oldValue === "") allSelects[selectId].selectedSample = value;

    allSelects.map((s,index)=>{
      if(selectId !== index){
        s.values.map((v)=>{
          if(`${v.cod}` === `${value}`) v.active = false;
          if(oldValue !== "" && oldValue === v.cod) v.active = true;
        })
      }

    })

    setSelects(allSelects);
  }

  async function saveSequence(name, seq){

    let data = {
      name,
      experiment_id: experiment.id,
      sequence:seq.map((v)=> v).join("##")
    }

    await API.post("sequences", data)
    .then((res)=>{
      let obj =  {seq_id:res.data.sequence.id,sequences:seq}
      setSequences(state => [...state, {seq_id:res.data.sequence.id,sequences:seq}]);
      setSequencesOnParent(state => [...state, { seq_id:res.data.sequence.id,sequences:seq }]);
      initialSampleState();
    })
    .catch((err)=> console.log(err))

  }

   async function addNewSequence(){

    let values = [...selects].map((s)=> s.selectedSample??"");

    if(values.length === selects.length){

      saveSequence(sequences.length + 1, values);

    }
  }

  async function deleteSample(item){
    try {
      // resgatar as informações da amostra selecionada
      // deletar a amostra
      const id = item
      const confirmation = window.confirm("Deseja mesmo deletar a amostra?")
      if(confirmation){
        await API.delete('samples/'+id).then(
          ()=>{
            const updatedItems = samples.filter(item => item.id !== id);
            setSamples(updatedItems);
            setSamplesOnParent(updatedItems);
          }
        )
      }
    } catch (error) {
      console.log('error deleting sample')
    }
  }

  async function deleteSequenceTriangle(item){
    try {
      const seq_id = item.seq_id
      
        await API.delete('sequences/'+seq_id).then((res)=>{
         console.log('response',res.data)
          
        })
      
    } catch (error) {
      console.log('error deleting sequence')
    }
  }

 async function deleteSequence(item){
    try {
      const seq_id = item.seq_id
      const confirmation = window.confirm("Deseja mesmo deletar o sequência?")
      if(confirmation){
        await API.delete('sequences/'+seq_id).then(()=>{
          const updatedItems = sequences.filter(item => item.seq_id !== seq_id);
          setSequences(updatedItems);
          setSequencesOnParent(updatedItems);
        })
      }
    } catch (error) {
      console.log('error deleting sequence')
    }
  }

  return(
    <div style={{ display:'flex', flexDirection:'column', alignItems:'center', width:'50rem', height:'30rem' }}>

      {selectedPage === 0?
        <div style={{ display:"flex", width:"100%", flexDirection:"column", padding:10}}>

        <div style={{ display:'flex',justifyContent:'center', alignContent:'center', width:'100%', borderRadius:'2rem', padding:10, marginBottom:30  }}>
          <div style={{display:'flex', flexDirection:'column',width:'100%',alignItems:'center', justifyContent:'center'}}>
            {samples.length > 0?<p>Amostras</p>:""}
            <form
              style={{ display:'flex', width:'100%', flexDirection:'row'}}
              onSubmit={(e) =>{
                e.preventDefault();
            }}>
              <div style={{ display:"flex", width:'100%', justifyContent:'space-around', alignItems:"center"}}>
                {
                  type == 'Triangle' ?
                  <div style={{ display:"flex", width:'100%', justifyContent:'space-around', alignItems:"center"}}>
                    <Input style={{ borderColor: error ? 'red' : 'initial' }} ref={ref} value={currentData.cod} placeholder="Código 1" onChange={(e)=> setCurrentData(d => ({...d, cod: e.target.value}))}/>
                    <Input style={{ borderColor: error ? 'red' : 'initial' }}  value={currentData.second_code} placeholder="Código 2" onChange={(e)=> setCurrentData(d => ({...d, second_code: e.target.value}))}/>
                    <Input value={currentData.name} placeholder="Nome" onChange={(e)=> setCurrentData(d => ({...d, name: e.target.value}))}/>
                  </div>
                  :
                  <div style={{ display:"flex", width:'100%', justifyContent:'space-around', alignItems:"center"}}>
                    <Input style={{ borderColor: error ? 'red' : 'initial' }} ref={ref} value={currentData.cod} placeholder="Código" onChange={(e)=> setCurrentData(d => ({...d, cod: e.target.value}))}/>
                    <Input value={currentData.name} placeholder="Nome" onChange={(e)=> setCurrentData(d => ({...d, name: e.target.value}))}/>
                    <Input value={currentData.obs} placeholder="Observação" onChange={(e)=> setCurrentData(d => ({...d, obs: e.target.value}))}/>
                  </div>
                }
                
                <div style={{display:'flex', overflow:'hidden' }}>
                  <BallButton
                    type="submit"
                    onClick={()=>addNewSample()}
                    color={"#CCC"}
                  >
                      +
                  </BallButton>

                </div>
              </div>
            </form>

            <div style={{ display:'flex', justifyContent:'initial', marginLeft:'4%'}}>
                {error && <p style={{ color: 'red', fontSize:'12px' }}>{error}</p>}
            </div>

          </div>
        </div>
        {/* amostra */}
        <ScrollableDiv>
        {samples.length > 0?
          samples.map((item,index)=>
          {
            return(
              item.second_code !== '' ? 
              <ListItem key={index}>
                <div style={{ width:'20%' }}>{item.cod}</div>
                <div style={{ width:'20%' }}>{item.second_code}</div>
                <div style={{ width:'20%', textAlign:"center" }}>{item.name}</div>
                <div style={{ width:'20%', textAlign:"center" }}>{item.obs !== ''?truncate(item.obs,29):"-"}</div>
                <div>
                  <img  src={deleteImg} alt="Deletar" onClick={()=>deleteSample(item.id)}/>
                </div>
              </ListItem>
              :
              <ListItem key={index}>
                <div style={{ width:'25%' }}>{item.cod}</div>
                <div style={{ width:'25%', textAlign:"center" }}>{item.name}</div>
                <div style={{ width:'25%', textAlign:"center" }}>{item.obs !== ''?truncate(item.obs,29):"-"}</div>
                <div>
                  <img  src={deleteImg} alt="Deletar" onClick={()=>deleteSample(item.id)}/>
                </div>
              </ListItem>
            )
          }
          )
          :
          <div style={{ display:"flex", flexDirection:"column", width:"100%", justifyContent:"center", alignItems:"center" }}>
        <div style={{ display:"flex", flexDirection:"column", width:"100%", justifyContent:"center", alignItems:"center", marginBottom:20}}>
        <img src={datasheetImg}/>
        {file?<p style={{ marginBottom:20 }}>{file.target.files[0].name}</p>:<></>}
        <label style={{ cursor:"pointer", padding:12, backgroundColor:"#55179A", color:"white", borderRadius: "2rem" }} htmlFor="uploadFile">{!file?"Escolher planilha":"Mudar planilha"}</label>
         <input onChange={(f)=>setFile(f)} style={{ display:"none" }} type="file" accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="uploadFile" name="uploadFile"/>
         </div>
         <p>Sem amostras. Cadestre manualmente ou insira uma planilha com amostras pré-cadastradas</p>
      </div>
        }
         </ScrollableDiv>
         </div>
      :

        <div style={{ display:"flex", width:"100%", flexDirection:"column", padding:10 }}>
          <div style={{ display:'flex',flexDirection: 'column',alignItems:'center',justifyContent:'center', width:'100%', borderRadius:'2rem', padding:10, marginBottom:30 }}>

            {selects.length > 0?<p>Sequência</p>:""}

            {selects.length > 0?
              <>
              
              {
              type !== 'Triangle' ?
              <div style={{ display:'flex', flexDirection:'row', alignItems:'center', width:"100%", justifyContent:"space-around" }}>
 
                 <div style={{ display:'flex', flexDirection:'row', width:"100%", justifyContent:"space-around" }}>
                   {selects.map((s, id)=>(
                   <Select key={`selectSample${id}`} value={s.selectedSample} onChange={(e)=> selectSample(id,e.target.value)} placeholder="Selecione...">
                     <option value={""}>...</option>
                     {
                       s.values.map((v, index)=> v.active?<option key={`selectOptions${index}`} value={v.cod}>{v.cod}</option>:'')
                     }
                   </Select>
                   ))
                   }
                 </div>
                 
                 <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                   <BallButton
                     type="submit"
                     onClick={()=> addNewSequence()}
                     color={"#CCC"}> +
                   </BallButton>
                 </div>
               </div>
              : <></> 
              }

              </>

              :

              <div style={{ display:"flex", flexDirection:"column", width:"100%", height:"100%", justifyContent:"center", alignItems:"center" }}>
              <div style={{ display:"flex", flexDirection:"column", width:"100%", justifyContent:"center", alignItems:"center"}}>
              <img src={datasheetImg}/>
              {file?<p style={{ marginBottom:20 }}>{file.target.files[0].name}</p>:<></>}
              <label style={{ cursor:"pointer", padding:12, backgroundColor:"#55179A", color:"white", borderRadius: "2rem" }} htmlFor="uploadFile">{!file?"Escolher planilha":"Mudar planilha"}</label>
               <input onChange={(f)=>setFile(f)} style={{ display:"none" }} type="file" accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="uploadFile" name="uploadFile"/>
               </div>
               <p>Você precisa cadastrar as amostras primeiro ou subir de uma planilha</p>
            </div>
            }

          </div>
          
          {/* Sequencia */}
          <ScrollableDiv>
          {
            
            sequences.map((item,index)=>{
              return (
              <ListItem key={`${item[0]}${index}`}>
                  <p style={{ alignSelf:'begin' }}>{`${index + 1}ª`}</p>
                  <p>{item.sequences.join(", ").replace(/, ([^,]*)$/, ' e $1')}</p>
                  <div>
                    {type == 'Triangle' ? <></>:
                      <img  src={deleteImg} alt="Deletar" onClick={()=>deleteSequence(item)}/>
                    }
                  </div>
              </ListItem>
            )})
          }
    </ScrollableDiv>
  </div>

  }
  <div style={{ display:'flex', position:'absolute', paddingTop:20,paddingBottom:20, bottom:0, width:'100%', justifyContent:'center', backgroundColor:'white', borderTop:'1px solid #f2f2f2'}}>
    <CustomButtomBordered onClick={()=> setSelectedPage(0)} style={
      {color: selectedPage == 0 ? "#FFF" : 'var(--purple-main)', 
      backgroundColor:  selectedPage == 0 ? 'var(--purple-main)' : 'white'}}>Amostras</CustomButtomBordered>
    <CustomButtomBordered onClick={()=> setSelectedPage(1)} 
    style={
      {color: selectedPage == 1 ? "#FFF" : 'var(--purple-main)', 
      backgroundColor:  selectedPage == 1 ? 'var(--purple-main)' : 'white'}}
    >Sequências</CustomButtomBordered>
  </div>
  </div>
  )
}

export default SampleAndSequence;
