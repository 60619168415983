import styled, { keyframes } from 'styled-components'
import editImg from '../../assets/images/edit-grey.svg'

export const Container = styled.div`

    label > button {
        pointer-events: none;
    }

    label{
        cursor: pointer;
    }

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto;
    /* padding-bottom: 5rem; */
    border-radius: 1rem;
    /* margin-top: 2.8rem; */

    background: #F2F2F2;
    overflow-y: hidden;
    overflow-x: hidden;


`;

const pulse = keyframes`
from {
  transform: scale(1);
}

to {
  transform: scale(1.1);
}
`;

export const Logo = styled.div`

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 1rem;
  left: 1.25rem;
  background: #FFF;
  height: 4.1rem;
  width: 4.1rem;
  border-radius: 2.5rem;
  z-index: 100;



  img{
      width: 3.5rem;

      animation: ${pulse} 2s infinite alternate;

  }

  h2{
    padding-left:2rem ;
    color: var(--purple-main);
  }


`;


export const LeftCol = styled.div`

    width: 23%;
    height: 80vh;
    border-radius: 1rem;
    /* background: #E5E4E2; */
    padding: 1rem;
    position: relative;
    overflow-y: auto;

    ::-webkit-scrollbar{
    width:6px;
    }
    ::-webkit-scrollbar-track{
    background:#f7f7f7;
    border-radius:3px;
    }
    ::-webkit-scrollbar-thumb{
    background:var(--purple-second);
    border-radius:3px;
    }

    span{
        img{
            max-width: 1rem;
            max-height: 1rem;
            position: absolute;
            top: 1.5rem;
            right: 1.5rem;
            filter: brightness(.9);
        }

        textarea{
            font-size: .8rem;
            color: var(--text-body);
            width: 100%;
            padding: .7rem;
            border: 1px solid #FFF;
            border-radius: 1rem;
            margin-top: .5rem;

        }

        p{
            padding-left: .7rem;
            margin-top: .4rem;
            font-size: .8rem;
            font-weight: 600;
            color: var(--text-body);
        }

        select{
                -webkit-appearance: none;
                -moz-appearance: none;
                margin-top: .5rem;
                margin-left: .1rem;
                padding-left: 1rem;
                border: 0;
                border-radius: 1rem;
                width: 98%;
                height: 2rem;
                background-color:rgba(255,255,255,0.5);
                color: #ADADAD;
            }


    }

`;


export const CenterCol = styled.div`

    width: 60%;
    height: 80vh;
    border-radius: 2px;
    padding: 1rem;
    /* padding-bottom: 4rem; */
    position: relative;
    box-shadow: 3px 0px 10px -4px rgba(0, 0, 0, 0.2);

    margin: 1rem 0;

    background: #FFF;

    // span{
    //     img{
    //         max-width: 1rem;
    //         max-height: 1rem;
    //         position: absolute;
    //         top: 1.5rem;
    //         left: 37%;
    //         filter: brightness(.9);
    //     }
    // }

    div{
        display: inline-flex;
        align-items: center;
        justify-content: space-around;
        width: 60%;

        p{
            cursor: pointer;
            font-size: .8rem;
            color: var(--text-body);
        }
    }

`;

export const SecondLine = styled.span`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 10px .6rem;

    input{
        border: var(--text-body);;
    }

    p{
        cursor: pointer;
        font-size: .8rem;
        color: var(--text-body);
        margin: 0 .6rem;
    }
`;

export const RightCol = styled.div`

    width: 23%;
    height: 80vh;
    border-radius: 1rem;
    /* background: #E5E4E2; */
    padding: 1rem;
`;


export const CustomTitle = styled.input`
    height: 2rem;
    color: var(--purple-main);
    font-size: 1.2rem;
    font-weight: 600;
    background: transparent;
    width: ${(props)=>props.size};
    border: 0;
    padding: 0 .5rem;
    border: 1px solid transparent;
    outline:none;

    // background-image: url(${editImg});
    // background-repeat: no-repeat;
    // background-position: left 10px center;
    // background-size: 14px 14px;

    &:hover{
        border: 1px solid #CCC;
        border-radius: .5rem;
    }
`;


export const CustomButtom = styled.button`
    font-weight: 600;
    height: 2rem;
    padding: 0 .5rem;
    color: #FFF;
    background: var(--purple-main);
    border: 2px solid var(--purple-main);
    border-radius: 1rem;

    &:hover{
        color: var(--purple-main);
        background-color: transparent;
    }
`;


export const CustomButtomBordered = styled.button`
    font-weight: 600;
    height: 2rem;
    padding: 0 .5rem;
    color: var(--purple-main);
    background: transparent;
    border: 2px solid var(--purple-main);
    border-radius: 1rem;
    transition: .2s;

    &:hover{
        color: #FFF;
        background-color: var(--purple-main);
    }

    & + button {
        margin-left: 1rem;
    }
`;

export const BallButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    background: ${(props)=>props.color?props.color:'var(--purple-main)'};
    border: 1px solide var(--purple-main);
    margin: 0 auto;

    svg{
        max-width: 1rem;
        max-height: 1rem;
        margin:0 !important;
        top: unset;
        left: unset;

    }

    @keyframes growButton{
      from { transform: scale(1,1) }
      to { transform: scale(1.2,1.2) }
    }

    @keyframes ungrowButton{
      from { transform: scale(1.2,1.2) }
      to { transform: scale(1,1) }
    }

    &:hover{
        animation: growButton .5s;
        animation-fill-mode: both;
    }
    &:not(:hover){
      animation: ungrowButton .5s;
      animation-fill-mode: both;
    }

`;

export const QuizArea = styled.span`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem;
    margin-top: .5rem;

    width: 100%;
    height: 67vh;
    overflow-y: auto;

    ::-webkit-scrollbar{
    width:6px;
    }
    ::-webkit-scrollbar-track{
    background:#f7f7f7;
    border-radius:3px;
    }
    ::-webkit-scrollbar-thumb{
    background:var(--purple-second);
    border-radius:3px;
    }

    :not(:hover){
      overflow-y: hidden;
    }

`;

export const PageArea = styled.span`
  display:flex;
  flex-direction:column;
  height: 75vh;
  width: 100%;

  scrollbar-width: thin;
  scrollbar-color: #888 #f5f5f5;
  margin-top: 20px;

  ::-webkit-scrollbar{
    width:6px;
    }
    ::-webkit-scrollbar-track{
    background:#f7f7f7;
    border-radius:3px;
    }
    ::-webkit-scrollbar-thumb{
    background:var(--purple-second);
    border-radius:3px;
    }

`;

export const Testers = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 .6rem;

    input{
        margin: .5rem 1rem 0 1rem;
        padding: 0 1rem;
        border: 0;
        border-radius: 1rem;
        width: 50%;
        height: 2rem;
        background: #FFF;
        color: #ADADAD;
    }

    p{
            padding-left: 0.1rem !important;
            margin-top: .4rem;
            font-size: .8rem;
            font-weight: 600;
            color: var(--text-body);
        }
`;

export const Input = styled.input`

    padding:5px;
    border: none;
    border-bottom: solid;
    border-bottom-width: 1.3px;
    border-bottom-color: #56179A;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size:14px;
    outline: none;
    color: #2E2E2E;
    max-height:30px;

    &:focus{
       border-bottom-width: 2px;
   };


  //  animation: drawLine .9s 1.0s;
  //  animation-fill-mode: both;
  //
  //    @keyframes drawLine {
  //  from {
  //    opacity:0;
  //    width: 0%;
  //  }
  //  to {
  //    opacity:1;
  //    width: 100%;
  //  }
  // }

`;

export const Select = styled.select`

-webkit-appearance: none;
 -moz-appearance: none;

  option {
    width: calc(100% - 12px); // Adjustable
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  padding:5px;
  border: none;
  border-bottom: solid;
  border-bottom-width: 1.3px;
  border-bottom-color: #56179A;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size:14px;
  outline: none;
  color: #2E2E2E;
  max-height:30px;

  &:focus{
     border-bottom-width: 2px;
 };
  cursor:pointer;

`;

export const ScrollableDiv = styled.div`
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f5f5f5;
  margin: 10px;

  ::-webkit-scrollbar{
    width:6px;
    }
    ::-webkit-scrollbar-track{
    background:#f7f7f7;
    border-radius:3px;
    }
    ::-webkit-scrollbar-thumb{
    background:var(--purple-second);
    border-radius:3px;
    }


  `
export const ScroolView = styled.div`
  display:flex;
  z-index:0;
  overflow-y:auto;
  max-height: 200px;
  flex-direction:column;
  width:100%;
  scroll-behavior:smooth;

  scrollbar-width: thin;
  scrollbar-color: #888 #f5f5f5;

  ::-webkit-scrollbar{
    width:6px;
    }
    ::-webkit-scrollbar-track{
    background:#f7f7f7;
    border-radius:3px;
    }
    ::-webkit-scrollbar-thumb{
    background:var(--purple-second);
    border-radius:3px;
    }

`;
