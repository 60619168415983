import { useParams } from "react-router-dom";
import { ClientLogo, Container, Logo, Message, Page } from "./styles";
import logoImg from '../../assets/images/logo.svg'
import { useEffect, useState, useRef } from "react";
import API from "../../services/api";
import { ItemForm } from "./ItemForm";

const AnswerForm = ({preview})=>{

    const [ experiment, setExperiment ] = useState({})
    const [ activePage, setActivePage ] = useState({})
    const [ pages, setPages ] = useState([])
    const [ hasFinished, setHasFinished ] = useState(false)
    const [logoClient,setLogoClient] = useState('')
    const { hashId } = useParams();
    const [formId, setFormId] = useState(0)


    const topRef = useRef(null);

    const samples = ['123','321','213']
    const [activeSample, setActiveSample] = useState(0)


    async function findByHash(){
        const { data } = await API.get('/experiments/findbyhash/'+hashId)
        setExperiment(data.experiment)
    }

    async function getPages(id=0) {


        const { data } = await API.get('/findbyexperimentid/'+experiment.id)
    
        if (data) setPages(data.pages)
    
    
        if(data.pages.length>0){
          // setSelectedPage(data.pages[0]?.id)
          if(id>0){
            const selectedPage = data.pages.find(o => o.id === id);
            
            setActivePage(selectedPage)
    
          }else{
            setActivePage(data.pages[0])
          }
          
        }
        
    }

    async function cleanPageAnswers(){

        setActivePage({})

    }

    async function nextPage(){
        if(activePage.repeat){
            if(samples[activeSample] === samples[samples?.length-1]){
                const index = pages.findIndex(x => x.id ===activePage.id);
                setActivePage(pages[index+1])
            }else{
                setActiveSample(activeSample+1)
                topRef.current?.scrollIntoView({behavior: 'smooth'});
                const index = pages.findIndex(x => x.id ===activePage.id);
                cleanPageAnswers()
                setActivePage(pages[index])

            }

        }else{
            const index = pages.findIndex(x => x.id ===activePage.id);
            setActivePage(pages[index+1])
        }
        
    }

    async function saveForm(){

            const {data} = await API.post('forms',{experiment_id:experiment.id})
            if(data){
                setFormId(data?.form?.id)
            }
        
    }

    


    useEffect(()=>{
        findByHash()
        saveForm()
    },[])

    useEffect(()=>{
        getPages()
    },[experiment])

    return(
        <Container>
            
            {
                hasFinished
                ? 
                <Message>
                    <h5>Obrigado por sua participação!</h5>
                </Message> 
                :
                <>
                    <Logo ref={topRef} className='testanimation'>
                        <img className='testanimation' src={logoImg} alt="Sensorie"/>
                    </Logo>
                    
                    <ClientLogo>
                        {
                            logoClient ? <img className='testanimation' src={logoClient} alt="Sensorie"/>: <></>
                        }
                        
                    </ClientLogo>
                    <Page>
                        {
                            activePage.repeat
                            ?<p>Amostra: {samples[activeSample]}</p>
                            :null
                        }
                        {/* <p>responder form {hashId}</p> */}
                        {
                        activePage?.items !==undefined && activePage?.items?.length > 0
                        ? activePage?.items?.map((question) => <ItemForm key={question.id} 
                                                                        data={question} 
                                                                        preview={preview}
                                                                        formId={formId}
                                                                        sample={activePage?.repeat?samples[activeSample]:'none'}
                                                                        />)
                        : null
                    }

                    {
                        activePage !==undefined && activePage?.id !== pages[pages?.length-1]?.id
                        ?<button onClick={nextPage}>Próxima</button>
                        :<button onClick={()=>setHasFinished(true)}>Finalizar</button>
                    }
                    </Page>
                </>
            }

        </Container>
    )

}

export default AnswerForm;