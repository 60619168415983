import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid';

import {
  Container,
  LeftCol,
  CenterCol,
  SecondLine,
  QuizArea,
  RightCol,
  CustomTitle,
  CustomButtomBordered,
  BallButton,
  PageArea,
  ScrollableDiv
} from './styles'

import { useNavigate, useParams } from 'react-router-dom';

// import editImg from '../../assets/images/edit-grey.svg'

import questionImg from '../../assets/images/question.svg'
import imageImg from '../../assets/images/image.svg'
import videoImg from '../../assets/images/video.svg'
import textImg from '../../assets/images/text.svg'
import { useModal } from '../Pattern/Modal';
import visualizeImg from '../../assets/images/visualize.svg'
// import logoImg from '../../assets/images/logo.svg'

import PageThumb from './PageThumb'
import { ItemBox } from './ItemBox'
import SampleAndSequence from './SampleAndSequence';

import { ScroolView } from './styles';

import API from '../../services/api';
import Cookie from 'js-cookie';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from "react-grid-dnd";

export const NewExperiment = ({ isOpen, onRequestClose }) => {

  const navigate = useNavigate();
  const { addModal, closeModal } = useModal();
  const [experiment, setExperiment] = useState(null)
  const [activePage, setActivePage] = useState(null)
  const [samples, setSamples] = useState([]);
  const [sequences, setSequences] = useState([]);
  const [pages, setPages] = useState([]);
  const [loadingPages, setLoadingPages] = useState(false);
  const [focusOnEnd, setFocusOnEnd] = useState(false)
  const { hashId, type} = useParams();
  const bottomRef = useRef(null);

  async function launchExperiment(){
    

    const confirmation = window.confirm("Deseja realmente lançar o experimento?")
    if(confirmation){
      if(sequences.length > 0){
        await API.put('experiments/' + experiment.id, { status:"RELEASED" }).catch(error => window.confirm('erro ao atualizar o experimento'))
        navigate('/experiments')
      }else{
        addModal({
          body:<p style={{ color:"#575757" }}>
            É necessário adicionar ao menos uma sequência para lançar o experimento.
          </p>,
          buttonPrimary:{ label:"Ok", function: ()=>{
            closeModal()
          }}
        })
      }
    }
  }

  async function findByHash(hash){

    const { data } = await API.get('/experiments/findbyhashsensorie/' + hash);

    setExperiment(data.experiment);
  }

  async function getSamplesAndSequences(){

    await API.get(`samples/getByExperimentId/${experiment.id}`)
    .then((res)=> setSamples(res.data))
    .catch((err)=> console.log(err))

    await API.get(`sequences/getByExperimentId/${experiment.id}`)
    .then((res)=>
    setSequences(res.data)
    )
    .catch((err)=> console.log(err))

  }

  async function saveExperiment(){

    if(experiment.id == 0){

      let payload = {
        name:experiment.name,
        description:experiment.description,
        user_id: parseInt(Cookie.get('sensorie-user-id')),
        hash:uuidv4(),
        type: type
      }
      
      const { data } = await API.post('/experiments', payload).catch(error => window.confirm('erro ao salvar o experimento'))

      if(data.status == 'created'){

        Cookie.set("sensorie-hash-experiment", data.hash);
        Cookie.set("sensorie-name-experiment", data.name);
        Cookie.set("sensorie-type-experiment", type);

        setExperiment(data.experiment);
      }

      return data.experiment

    }else{

      API.put('/experiments/' + experiment.id, experiment)
      .catch((err)=>console.log(err))

    }

  }

  const savePage = useCallback(async () => {
    try {
      if(activePage?.id !== 0 && activePage !== null){

        await API.put('pages/' + activePage.id, activePage).then(() => {
          let changedIndex = null;
          let currentPages = pages.map((a, index) => {
            if (activePage.id == a.id) changedIndex = index;
            return ({...a });
          });
          
          currentPages[changedIndex] = activePage;
          setPages(currentPages);
        });
      }
    } catch (error) {
      console.log('saving page update', error);
    }
  }, [pages]);

 function removeItemFromPage(id){

  let aPage = {...activePage};
  let deleteIndex = null;

  aPage.items.map((it,index)=>{
    if(it.id == id) deleteIndex = index;
  })

      aPage.items.splice(deleteIndex,1);
      setActivePage(aPage);

      let currentPageIndex = null;
        let currentPages = pages.map((a,index)=>{
          if(activePage.id == a.id) currentPageIndex = index;
          return ({...a})
        });

        currentPages[currentPageIndex] = aPage;
        setPages(currentPages);

 }

  async function addQuestion(type) {

    const payload = {
      "page_id":activePage.id,
      "type": type,
      "description": null,
      "order": (activePage.items?.length??0) + 1,
      "options": null,
      "mandatory": false
    }

    await API.post('items',payload).then((res)=>{

      let aPage = {...activePage};

      if(aPage.items) aPage.items.push(res.data.item);
      else aPage.items = [res.data.item]

      setActivePage(aPage);

      let addedIndex = null;
        let currentPages = pages.map((a,index)=>{
          if(activePage.id == a.id) addedIndex = index;
          return ({...a})
        });

        currentPages[addedIndex] = aPage;
        setPages(currentPages);

    })

    setFocusOnEnd(true)

  }

  async function upQuestion(id) {

    await API.post('reorderitems',{id:id, type:'toup'})
    getPages(activePage.id)

  }

  async function downQuestion(id) {

    await API.post('reorderitems',{id:id, type:'todown'})
    getPages(activePage.id)
  }

  async function removePageThumb(e) {

    const confirmation = window.confirm("Tem certeza que deseja excluir a página?")

    if(confirmation){
      await API.delete('pages/'+ e.id)
      getPages()
    }
  }

  function changeQuestionsOnPage() {

    const selectedPage = pages.find(item => item.id == activePage.id);

    if (selectedPage) {
      setActivePage(selectedPage);
    }
  }

  async function getPages(id=0) {
    setLoadingPages(true);
    const { data } = await API.get('/findbyexperimentid/'+experiment.id).catch(error => window.confirm('erro ao recuperar as páginas'))

    if (data && id == 0) setPages(data.pages)

    if(data.pages.length>0){

      if(id > 0){
        const selectedPage = data.pages.find(o => o.id == id);

        setActivePage(selectedPage);

      }else{
        setActivePage(data.pages[0])
      }

    }
    setLoadingPages(false);
  }

  async function addPage(){
    let page = {}
    if(experiment.id == 0){

      await saveExperiment().then(
        async (res)=>
        {
          if(pages.length <=0){
            page = {
              name: 'Nova página',
              experiment_id: res.id,
              page_number: pages.length + 1
            }
          }

          await API.post('/pages', page).then(async ()=>{
            await API.get('/findbyexperimentid/'+res.id).then((response)=>{

              setPages(response.data.pages)
              setActivePage(response.data.pages[0])
            })
          })
        }
      ).catch(error => window.confirm('erro ao adicionar a página'))

    }else{

      page = {
        name: 'Nova página',
        experiment_id:experiment.id,
        page_number: pages.length + 1
      }

      await API.post('/pages', page).then((res)=>{
        let currentPages = pages.map((a)=>({...a}));
        currentPages.push(res.data.page);
        setPages(currentPages);
        setActivePage(res.data.page);
      }).catch(error => window.confirm('erro ao adicionar a página'))

    }
  }

  function previewForm(){
    window.open('/preview/'+ hashId, '_blank', 'noopener,noreferrer');
  }

  useEffect(() => {
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [focusOnEnd])

  useEffect(()=>{

    let hash = hashId??parseInt(Cookie.get('sensorie-hash-experiment'));

    if(hash && hashId !=='new'){

      findByHash(hash);

    }else if(hashId == 'new'){
      setExperiment({ id:0, name: "Novo Experimento", description:"", hash:"", status:"" });
      setActivePage({ id:0, name: "Nova página", repeat: false })
    }
  },[hashId])

  useEffect(()=>{
    if(experiment && experiment.id != 0 && hashId && hashId.length>0 && hashId!=='new'){
      getPages();
      getSamplesAndSequences();
    }
  },[experiment, hashId])

  function openSampleAndSequenceModal(pages){

    if(type == 'Triangle'){
      addModal({
        body: <SampleAndSequence
          setSamplesOnParent = {setSamples}
          setSequencesOnParent = {setSequences}
          samplesOnParent = {samples}
          sequencesOnParent = {sequences}
          activePage = {pages}
          experiment = {experiment}
          saveExperiment={saveExperiment}
          type={type}
          />,
        buttonPrimary: { label:"Concluir" }
      });
    }else{
      addModal({
        body: <SampleAndSequence
          setSamplesOnParent = {setSamples}
          setSequencesOnParent = {setSequences}
          samplesOnParent = {samples}
          sequencesOnParent = {sequences}
          activePage = {pages}
          experiment = {experiment}
          saveExperiment={saveExperiment}
          type={type}
          />,
        buttonPrimary: { label:"Concluir" }
      });
    }
  }

  useEffect(()=>{
    savePage()
  },[activePage?.repeat, activePage?.name])

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
      const nextState = swap(pages.map((a)=>({...a})), sourceIndex, targetIndex);

      nextState.map((ns, index)=>{
        ns.page_number = index + 1;
      })
      API.post('reorderpages', { pages: nextState });
      setPages(nextState);
    }

  return (

    <Container>
      {experiment && pages?
      <>
    <LeftCol>
      <span>
        <CustomTitle
          size={'100%'}
          key={'ExperimentTitle'}
          value={experiment.name}
          id={experiment.name}
          onChange={(e) => setExperiment({ ...experiment, name: e.target.value })}
          // onClick={()=>{ saveExperiment()}}
          onBlur={()=>{if(experiment?.name!==null)saveExperiment()}}
          />

      </span>

      <span>
        <textarea
          style={{ marginTop:30 }}
          rows="5"
          key="TextArea"
          placeholder='Descrição'
          id={experiment.hash}
          value={experiment.description}
          onChange={(e)=>setExperiment({...experiment,description:e.target.value})}
          onBlur={()=>{if(experiment?.description!==null)saveExperiment()}}
        />

      </span>

      <div style={{ display:'flex', width:'100%', flexDirection:'row', alignItems:'center', marginTop:30, marginBottom:15 }}>
        <CustomButtomBordered onClick={()=> openSampleAndSequenceModal()} style={{ width:'100%' }} >Amostras ({samples.length})</CustomButtomBordered>
      </div>

      <ScroolView>
        {
          samples.map((sample, id)=>{
            return(
              sample.second_code == '' ?
              <div key={id} style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:"center", width:'98%', borderRadius:'7rem', fontSize:'12px', padding:'7px 7px 7px 1rem', marginBottom:10, backgroundColor:'#E7E7E7', color:'#323232' }}>
              {/*<p>{(id + 1)}</p>*/}
              <div key={sample.cod} style={{ width:"30%" }}>{sample.cod}</div>
              <div key={sample.name} style={{ width:"100%" }}>{sample.name}</div>
            </div>
            :
            <div key={id} style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:"center", width:'98%', borderRadius:'7rem', fontSize:'12px', padding:'7px 7px 7px 1rem', marginBottom:10, backgroundColor:'#E7E7E7', color:'#323232' }}>
            {/*<p>{(id + 1)}</p>*/}
            <div key={sample.cod} style={{ width:"20%" }}>{sample.cod}</div>
            <div key={sample.second_code} style={{ width:"20%" }}>{sample.second_code}</div>
            <div key={sample.name} style={{ width:"100%" }}>{sample.name}</div>
          </div>
            )
          })


        }
      </ScroolView>

      <div style={{ display:'flex', width:'100%', flexDirection:'row', alignItems:'center', justifyContent:'space-between', marginTop:30, marginBottom:15 }}>
      <CustomButtomBordered onClick={()=> openSampleAndSequenceModal(1)} style={{ width:'100%' }} >Sequências {`(${sequences.length})`}</CustomButtomBordered>
      </div>
      <ScrollableDiv>

      {
        sequences?.map((sequence, index)=>{

          return (
           <div key={index} style={{ display:'flex', flexDirection:'row',  justifyContent:'space-between', alignItems:"center", width:'98%', borderRadius:'7rem', fontSize:'12px', padding:'7px 7px 7px 1rem', marginBottom:10, backgroundColor:'#E7E7E7', color:'#323232' }}>
             <div key={index + 1} style={{ width:"30%" }}>{`${index + 1}ª`}</div>
             <div key={sequence.id} style={{ width:"100%" }}>{
              sequence ?

                sequence.sequences.join(", ").replace(/, ([^,]*)$/, ' e $1')
              :
                sequence.sequences.join(", ").replace(/, ([^,]*)$/, ' e $1')
             }</div>
          </div>
        )})
      }
      </ScrollableDiv>

    </LeftCol>
    <CenterCol>
      {
        pages?.length > 0 && activePage
        ?<>
          <span>
            <CustomTitle  size={'40%'}
                          value={activePage.name??""}
                          id={activePage.id}
                          onChange={(e) => setActivePage({ ...activePage, name: e.target.value })}
                          onBlur={()=>{if(activePage?.name !== "Nova página")savePage()}}
                          />
          </span>
          {/* <div style={{ fontSize:'.7rem', color:'#323232' }}>
            Salvando automaticamente.
          </div> */}
          {type == 'Triangle' ?
             <SecondLine>
             <input type="checkbox" name="repeat" id="repeatPage" checked={activePage.repeat} onChange={(e)=>{
                 setActivePage({...activePage, repeat:Boolean(e.target.checked)})
               }}

               />
             <p>Página do teste triangular</p>
           </SecondLine>
          :
            <SecondLine>
              <input type="checkbox" name="repeat" id="repeatPage" checked={activePage.repeat} onChange={(e)=>{
                  setActivePage({...activePage, repeat:Boolean(e.target.checked)})
                }}
                />
              <p>Repetir para todas as amostras</p>
            </SecondLine>
          }
          <QuizArea>

              {
                activePage?.items?.length > 0
                  ? activePage.items.sort((a,b)=> a.page_number - b.page_number).map((question,index) => <ItemBox key={`${question.type}${question.id}`}
                                                                upQuestion={upQuestion}
                                                                downQuestion={downQuestion}
                                                                data={question}
                                                                updateItemOnPage={setActivePage}
                                                                setFocusOnEnd={setFocusOnEnd}
                                                                removeItemFromPage={removeItemFromPage}
                                                                isLast={question.id===activePage?.items[activePage?.items?.length-1].id}
                                                                activePage={activePage}
                                                                arrayIndex={index}
                                                                />)
                  : null
              }
              <div style={{display:'block', minHeight:'3rem'}} />
              <div ref={bottomRef} />

            <div style={{ width:'100%', display:'flex', justifyContent:'center', alignItems:'center', padding:20, borderRadius: '.3rem' }}>
            <div>
              <BallButton style={{ width:'2.5rem', height:'2.5rem', borderRadius:'2rem' }} onClick={()=>addQuestion('text')}> <img src={questionImg} alt="Adicionar pergunta"/></BallButton>
              <BallButton onClick={()=>addQuestion('image')} ><img src={imageImg} alt="Adicionar imagem"/></BallButton>
              <BallButton onClick={()=>addQuestion('video')}><img src={videoImg} alt="Adicionar vídeo"/></BallButton>
              <BallButton onClick={()=>addQuestion('paragraph')} ><img src={textImg} alt="Adicionar texto"/></BallButton>
              </div>
            </div>

          </QuizArea>
        </>
        :
        <div style={{ display:'flex', width:'100%', justifyContent:'center', alignItems:'center', color:"#575757" }}>
          Adicione sua primeira página clicando &nbsp;
          <BallButton style={{margin: 0}} color={'#CCC'} onClick={()=> addPage()}>+</BallButton>
        </div>
      }

    </CenterCol>
    <RightCol>
      <div style={{ display:"flex", flexDirection: "row", justifyContent:"space-around", alignItems:'center', width:"100%" }}>
      {/* <div style={{ display:'flex', justifyContent:'center', alignItems:'center' }}>
        <BallButton
          disabled={hashId==='new'}
          onClick={previewForm}
          // onClick={()=>addModal({ body: <p>Pré-visualização não disponível!</p>})}
        > <img src={visualizeImg} alt="Visualizar" /></BallButton>
      </div> */}
        <div style={{ display:'flex', fontSize:'.7rem', color:'#323232', backgroundColor:'#E7E7E7', padding: "0rem 0.5rem", borderRadius:20, height:"2rem", justifyContent:'center', alignItems:"center", color:"#575757" }}>
            Salvamento auto.
          </div>
        <CustomButtomBordered onClick={launchExperiment}>Lançar experimento</CustomButtomBordered>
      </div>


      <PageArea>

      <div style={{ maxHeight:'90%', overflowY:'scroll', overflowX: 'hidden'}}>
      <GridContextProvider onChange={onChange}>
         <GridDropZone
           id="pages"
           boxesPerRow={2}
           rowHeight={window.innerWidth >= 1540 ?  window.innerWidth >= 1630 ? window.innerWidth >= 1744 ? window.innerWidth >= 1850 ? window.innerWidth >= 2000 ? window.innerWidth >= 2300 ? 280 : 230 : 220 : 200 : 190 : 180 : 160}
           style={{ height: (window.innerWidth >= 1540 ?  window.innerWidth >= 1630 ? window.innerWidth >= 1744 ? window.innerWidth >= 1850 ?  window.innerWidth >= 2000 ? window.innerWidth >= 2300 ? 280 : 230 : 220 : 200 : 190 : 180 : 160) * Math.ceil(pages.length / 2) }}
         >
           {pages.sort((a,b) => a.page_number - b.page_number).map((page, index) => (
             <GridItem key={page.id}>
               <PageThumb
                      styles={{ cursor: "-webkit-grab" }}
                      remove={removePageThumb}
                      key={page.id}
                      data={page}
                      selectedPage={activePage.id === page.id}
                      setSelectedPage={() => getPages(page.id)}
                      pageNumber={index + 1}
                      disabled={loadingPages}
                    />
             </GridItem>
           ))}

         </GridDropZone>
       </GridContextProvider>
       </div>

       <div onClick={()=> addPage()} style={{ marginTop:10 }}>
         <BallButton color={'#CCC'}>+</BallButton>
       </div>

        </PageArea>

    </RightCol>
    </>
    :
    <></>
    }
    </Container>

  )
}

export default NewExperiment;
