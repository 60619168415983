import { Route, Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom';

import { Bar } from '../../components/Bar';

import Header from "../../components/Header";
// import Cookies from 'js-cookie';
// import RedirectToLogin from '../../components/RedirectToLogin/index.jsx';

const Private = ({ children }) => {
    // if(!Cookies.get('token_sensorie')) return <RedirectToLogin/>
    const location = useLocation();
    return (
      <>

      {location.pathname!=='/newexperiment'&&<Header />

      }
        <div style={{ display:'flex', justifyContent:'center', alignItems:'center', padding:'25px 35px 0px 35px' }}>
          {children?children:<Outlet/>}
        </div>
         <Bar />
      </>
    )
 }

 export default Private;
