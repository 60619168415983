import React, { createContext, useState, useContext, useEffect } from 'react';

// Components
import {
  Modal,
  Body,
  Footer,
  ButtonContainer,
  Button,
  Header,
  Title,
  Wrapper
  } from './styles.js';

//Context
export const Context = createContext()

//Provider
const ModalProvider = ({ children }) =>{

  const [ modalInfo, setModalInfo ] = useState({ visible: false});
  const openModal = () => setModalInfo({...modalInfo, visible: true});
  const closeModal = () => { setModalInfo({...modalInfo, visible: false}) };

  const addModal = ({ title = "", body = "", buttonPrimary = "", buttonSecondary = "", size="normal", type=false}) =>{
    setModalInfo({ title, body, buttonPrimary, buttonSecondary, size, visible: true, type})
  }


  return (
  <Context.Provider value={{ modalInfo, openModal, closeModal, addModal }}>
    {children}
    {<MyModal/>}
  </Context.Provider>
)
}

//Hook
const useModal = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useModal must be used within a ModalProvider');
    }

    return context;
};

const MyModal = () =>
{

  const { modalInfo, closeModal } = useModal();
  const { title, body, buttonPrimary, buttonSecondary, visible, type } = modalInfo;

  useEffect(()=>{

    if(visible) {
      document.body.style.overflowX = 'hidden'
    }else{
      document.body.style.overflowY = 'scroll'
      document.body.style.overflowX = 'hidden'

    } 

  },[visible])

  return(
    visible?
    <Modal id='chartModal'>

    <Wrapper style={{width: type == true ? '800px': ''}}>

    <Header size={modalInfo.size} title={title}>
      <Title style={{ color:'white', fontWeight:600 }}>
        {title}
      </Title>
    </Header>

      <Body size={modalInfo.size}>
        <div style={{
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          minWidth:'100%',
          width:'100%',
          padding: 'calc(30px - 1rem) 30px'
         }}>{body}</div>
      </Body>

      <Footer size={modalInfo.size}>
      <ButtonContainer style={{backgroundColor: buttonPrimary == '' ? '' : 'rgba(206, 212, 208, 0.5)' }}>

        {buttonPrimary !== ''
          ?
          <Button color={buttonPrimary.color??'#55179A'} onClick={()=>{
            if(buttonPrimary.function){
              buttonPrimary.function()
              closeModal()
            }else {
              closeModal()
            }
          }}>
            {buttonPrimary.label??'Entendi'}
          </Button>
          
          :
          <></>
          
        }

        {buttonSecondary !== ""?
        <Button color={buttonSecondary.color??'#EE4245'} onClick={()=>closeModal()}>
          {buttonSecondary.label??'Fechar'}
        </Button>
        :
        <></>
        }

        </ButtonContainer>
      </Footer>
      </Wrapper>

    </Modal>
    :
    <></>

  )

}

export { ModalProvider, useModal };
