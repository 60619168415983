import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Private from './private';

import Login from "../components/Login";
import ExperimentList from "../components/ExperimentList";
import NewExperiment from "../components/NewExperiment/";
import RedirectToLogin from '../components/RedirectToLogin';
import Settings from '../components/Settings';
import AnswerForm from '../components/AnswerForm';


export default function Screens(){

  return(
      <Router basename={'/sensorie'}>
        <Routes>
          <Route element = { <Login /> }  path="/" />
          <Route 
            element = { <AnswerForm preview={false} /> }  
            path="/answer/:hashId" 
          />
          <Route 
            element = { <AnswerForm preview={true} /> }  
            path="/preview/:hashId" 
          />
          {/* Dentro desse Route ficam as rotas privadas */}
          <Route element ={<Private/>}>
            <Route path="/experiments" element={<ExperimentList/>} />
            <Route 
              path="/newexperiment/:hashId/:type"
              loader={({ params }) => {
                console.log(params.hash); // "hotspur"
              }}
              action={({ params }) => {}}
              element={<NewExperiment/>} 
            />
            <Route path="/settings" element={<Settings />} />
          </Route>
          <Route element = {<RedirectToLogin />}  path="*" />
        </Routes>
      </Router>
  )

}
