import styled, { keyframes} from 'styled-components'

export const Container = styled.div`
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    background: #FFF ;
    width: 100%;

`;

const pulse = keyframes`
from {
  transform: scale(1);
}

to {
  transform: scale(1.1);
}
`;

export const Logo = styled.div`

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0.2rem;
  left: 0.2rem;
  /* width: 15rem; */
  height: 4rem;
  border-radius: 2.5rem;
  padding:12px;

  img{
    width: 3.5rem;
    background-color:white;
    border-radius:100%;
    border: solid;
    border-color: white;
    border-width: 3px;
    animation: ${pulse} 2s infinite alternate;
  }

  h2{
    padding-left:2rem ;
    color: var(--purple-main);
  }


`;
