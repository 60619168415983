import * as xlsx from 'xlsx';

export default function downloadCustomExcel(sheetData, fileName){
    const wb = xlsx.utils.book_new();
    sheetData.forEach(({ columns, data, sheetName }) => {
        const ws = xlsx.utils.aoa_to_sheet([columns, ...data]);
        xlsx.utils.book_append_sheet(wb, ws, sheetName || 'Sheet1');
    });
    
    xlsx.writeFile(wb,fileName);
}
